import { useEffect, useRef, useState } from "react";
import { Canceler } from "axios";
import {
    useAppTable,
    useAuthAction,
    useFormContainerState,
    useTrans,
} from "../../../hooks";
import { useAuthData } from "../../../contexts";
import {
    Button,
    VStack,
    HStack,
    Divider,
    Center,
    Text,
    Hide,
    Menu,
    MenuButton,
    Box,
    MenuList,
    MenuItem,
    useToast,
    useDisclosure,
} from "@chakra-ui/react";
import { AppSearch } from "../../../containers";
import {
    AppAlert,
    AppAlertDelete,
    AppIcon,
    AppPageHeader,
} from "../../../components";
import { UserList as ListComponent } from "./UserList";
import { UserForm as FormComponent } from "./UserForm";
import { User as Entity, UserApi as EntityApi } from "../../../apis";
import { useRecoilValue } from "recoil";
import { ActiveSchoolOrCompanyAtom } from "../../../atoms";
import { APPC, cPath, LSC, UTIL } from "../../../config";

export const UserPage = () => {
    // hook
    const { t } = useTrans();
    const toast = useToast();
    const formContainerState = useFormContainerState();
    const deleteAlertState = useDisclosure();
    const loginAlertState = useDisclosure();
    const { user, grant } = useAuthData();
    const activeSchoolOrCompany = useRecoilValue(ActiveSchoolOrCompanyAtom);
    const { tokenLoginAction } = useAuthAction();

    // state & const
    const [search, setSearch] = useState("");
    const [loadingList, setLoadingList] = useState(true);
    const [list, setList] = useState<Entity[]>([]);
    const [actionSelectId, setActionSelectId] = useState(0);

    const cancelTokenSourcesRef = useRef<Canceler[]>([]);

    const {
        table,
        pageIndex,
        pagesCount,
        pageSize,
        sortColumn,
        sortOrder,
        setPagesCount,
    } = useAppTable("firstName");

    const paging = {
        enabled: true,
        pageSizes: APPC.PAGE_SIZES,
        pageSize,
        pageIndex,
        pagesCount,
    };

    const fetchListData = async () => {
        if (!activeSchoolOrCompany && !grant.isSuperAdmin) {
            return false;
        }
        setLoadingList(true);
        const params = {
            itemsPerPage: pageSize,
        };
        params[`order[${sortColumn}]`] = sortOrder;
        if (search) {
            params["firstName"] = search;
        }

        if (!grant.isSuperAdmin) {
            if (activeSchoolOrCompany?.["@type"] === "School") {
                params["schools.id"] = activeSchoolOrCompany?.id;
            } else {
                params["companies.id"] = activeSchoolOrCompany?.id;
            }
        }

        await EntityApi.getCollection<Entity>(pageIndex + 1, params, (c) => {
            cancelTokenSourcesRef.current.push(c);
        })
            .then(({ errorMessage, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    setPagesCount(Math.ceil(response.totalItems / pageSize));
                    setList(response.items);
                }
            })
            .finally(() => setLoadingList(false));
    };

    const actionClickHandler = (key: string, data?: Entity) => {
        if (!data) {
            return;
        }
        switch (key) {
            case "login":
                setActionSelectId(data?.id);
                loginAlertState.onOpen();
                break;
            case "edit":
                formContainerState.open(data?.id);
                break;
            case "delete":
                setActionSelectId(data?.id);
                deleteAlertState.onOpen();
                break;
        }
    };

    const deleteHandler = async () => {
        if (actionSelectId < 1) {
            return;
        }
        setLoadingList(true);
        await EntityApi.deleteItem(actionSelectId)
            .then(({ errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else {
                    fetchListData();
                }
            })
            .finally(() => setLoadingList(false));
    };

    const loginHandler = async () => {
        if (actionSelectId < 1) {
            return;
        }
        EntityApi.postImpersonateRequest<
            { token: string | null },
            { userId: number }
        >({ userId: actionSelectId }).then(({ response, errorMessage }) => {
            if (errorMessage) {
                toast({
                    title: errorMessage,
                    status: "error",
                });
            } else if (response !== null) {
                if (response.token) {
                    localStorage.setItem(
                        LSC.APP_TOKEN_P_KEY,
                        localStorage.getItem(LSC.APP_TOKEN_KEY) || ""
                    );
                    tokenLoginAction(response.token);
                    setTimeout(() => {
                        document.location.href = cPath("ADMIN.DASHBOARD_PAGE");
                    }, 500);
                }
            }
        });
    };

    const handleCreate = (type: string) => {
        formContainerState.setEntityData({
            userType: type,
        });
        formContainerState.open(0);
    };

    // useEffect
    useEffect(() => {
        if (!formContainerState.isOpen) {
            fetchListData();
        }
    }, [
        pageIndex,
        pageSize,
        sortColumn,
        sortOrder,
        search,
        formContainerState.isOpen,
        activeSchoolOrCompany,
    ]);

    return (
        <>
            <AppPageHeader title={t("padm.UserPage:text.pageTitle")}>
                <HStack gap={4} py={{ base: 3.5, sm: 4, md: "1.125rem" }}>
                    <AppSearch onSearch={(value) => setSearch(value)} />
                    {UTIL.isUserCreate(user?.userType) && (
                        <>
                            <Center height="2.1875rem">
                                <Divider
                                    orientation="vertical"
                                    borderColor={"var(--chakra-colors-greyT80)"}
                                />
                            </Center>
                            <Menu placement="bottom-end">
                                <MenuButton
                                    as={Button}
                                    variant={"primary"}
                                    rightIcon={
                                        <AppIcon
                                            name="ics-chevron-bottom-alt"
                                            w="1rem"
                                        />
                                    }
                                >
                                    <Box display={"flex"} alignItems={"center"}>
                                        <AppIcon
                                            name="ics-plus-circle"
                                            w="1rem"
                                        />
                                        <Hide below="md">
                                            <Text ml={2}>
                                                {t(
                                                    "padm.UserPage:button.create"
                                                )}
                                            </Text>
                                        </Hide>
                                    </Box>
                                </MenuButton>
                                <MenuList>
                                    {UTIL.userCreateTypeAllow(
                                        user?.userType,
                                        activeSchoolOrCompany?.["@type"] ===
                                            "School"
                                    ).map((s) => (
                                        <MenuItem
                                            key={s}
                                            onClick={() => handleCreate(s)}
                                        >
                                            {t(`con.User:USERTYPE_${s}`)}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </Menu>
                        </>
                    )}
                </HStack>
            </AppPageHeader>
            <VStack p={6}>
                <ListComponent
                    table={table}
                    paging={paging}
                    list={list}
                    loadingList={loadingList}
                    onActionClick={actionClickHandler}
                />
                {deleteAlertState.isOpen && (
                    <AppAlertDelete
                        isOpenDelete={deleteAlertState.isOpen}
                        deleteHandler={() => deleteHandler()}
                        onCloseDelete={deleteAlertState.onClose}
                    />
                )}
                {loginAlertState.isOpen && (
                    <AppAlert
                        title={t("cmn:alert.login.title")}
                        isOpen={loginAlertState.isOpen}
                        onSuccess={loginHandler}
                        onCancel={loginAlertState.onClose}
                        successLable={t("cmn:alert.login.button.success")}
                        cancelLable={t("cmn:alert.login.button.cancel")}
                    >
                        <VStack py={6}>
                            <AppIcon
                                name="icl-key"
                                w="2.5rem"
                                color={"var(--chakra-colors-primary)"}
                            />
                            <Text textStyle={"boldMd"}>
                                {t("cmn:alert.login.message")}
                            </Text>
                        </VStack>
                    </AppAlert>
                )}
            </VStack>
            {formContainerState.isOpen && (
                <FormComponent formContainerState={formContainerState} />
            )}
        </>
    );
};
