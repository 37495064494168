import { FC, useRef, useState } from "react";
import { CropperRef } from "react-advanced-cropper";
import { useResolveUploadedPath } from "../hooks";
import { Avatar, Box, Input } from "@chakra-ui/react";
import { AppIcon } from "./AppIcon";
import "react-advanced-cropper/dist/style.css";
import { AppCropper } from "./AppCropper";
import { APPC } from "../config";
import imagePlaceholder from "../assets/images/imagePlaceholder.svg";

type AppImageUploaderProps = {
    fileType: string;
    fileName: string | undefined | null;
    onDone: (file: File | undefined) => void;
    isAvatar?: boolean;
};

export const AppImageUploader: FC<AppImageUploaderProps> = ({
    fileType,
    fileName,
    onDone,
    isAvatar,
}) => {
    const [imageName, setImageName] = useState(fileName);
    const cropperRef = useRef<CropperRef>(null);
    const [croppedFile, setCroppedFile] = useState<File | undefined>(undefined);
    const [showCropper, setShowCropper] = useState(false);
    const [uploadedFile, setUploadedFile] = useState<File | undefined>(
        undefined
    );
    const fileTypeInfo = APPC.BE.Upload.FILETYPEINFO[
        `FILETYPEINFO_${fileType}`
    ] as { ratio?: number | null };

    const onCropperDone = async () => {
        if (cropperRef?.current && uploadedFile) {
            await cropperRef?.current?.getCanvas()?.toBlob(
                async (blob) => {
                    if (blob) {
                        setCroppedFile(getFileFromBlob(blob, uploadedFile));
                        onDone(getFileFromBlob(blob, uploadedFile));
                    }
                },
                uploadedFile.type,
                1
            );
            setShowCropper(false);
        }
    };

    const onCropperClose = () => {
        setUploadedFile(undefined);
        setShowCropper(false);
    };

    const getFileFromBlob = (blob: Blob, file: File) =>
        new File([blob], file.name, {
            type: file.type,
            lastModified: file.lastModified,
        });

    const stencilOptions = {};
    if (fileTypeInfo.ratio) {
        stencilOptions["aspectRatio"] = fileTypeInfo.ratio;
    }

    return (
        <Box textAlign={"center"} w={"full"}>
            {isAvatar ? (
                <>
                    <Avatar
                        size="2xl"
                        src={useResolveUploadedPath(
                            fileType,
                            imageName,
                            croppedFile
                        )}
                        color={"var(--chakra-colors-primaryS10)"}
                        bgColor={"var(--chakra-colors-primaryT80)"}
                    ></Avatar>
                    <Input
                        id="image-upload"
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                            const file = e.target.files?.[0];
                            if (file) {
                                setUploadedFile(file);
                                setShowCropper(true);
                            }
                            e.target.value = "";
                        }}
                        cursor={"pointer"}
                        opacity={0}
                        position={"absolute"}
                        w={"full"}
                        h={"full"}
                        top={0}
                        left={0}
                    />
                </>
            ) : (
                <Box
                    w={"100%"}
                    h={"311px"}
                    border={"1px solid #999"}
                    borderRadius={"0.25rem"}
                    backgroundImage={
                        croppedFile || imageName
                            ? useResolveUploadedPath(
                                  fileType,
                                  imageName,
                                  croppedFile
                              )
                            : imagePlaceholder
                    }
                    backgroundSize={"contain"}
                    backgroundRepeat={"no-repeat"}
                    backgroundPosition={"center"}
                >
                    <Input
                        id="image-upload"
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                            const file = e.target.files?.[0];
                            if (file) {
                                setUploadedFile(file);
                                setShowCropper(true);
                            }
                            e.target.value = "";
                        }}
                        cursor={"pointer"}
                        opacity={0}
                        w={"full"}
                        h={"full"}
                        p={0}
                    />
                </Box>
            )}

            {(croppedFile || imageName) && (
                <Box
                    display={"flex"}
                    pos={"absolute"}
                    bottom={2}
                    right={2}
                    bg="#fff"
                    borderRadius={"full"}
                    padding={1}
                    onClick={() => {
                        setImageName("");
                        setCroppedFile(undefined);
                        setUploadedFile(undefined);
                    }}
                    cursor={"pointer"}
                >
                    <AppIcon
                        name="icl-trash"
                        color="var(--chakra-colors-destructive)"
                    />
                </Box>
            )}
            {showCropper && uploadedFile && (
                <AppCropper
                    cropperRef={cropperRef}
                    uploadedFile={uploadedFile}
                    fileTypeInfo={fileTypeInfo}
                    onDone={onCropperDone}
                    onClose={onCropperClose}
                />
            )}
        </Box>
    );
};
