import { useEffect } from "react";
import { useAuthAction } from "./hooks";
import RootRoutes from "./routes/RootRoutes";

function App() {
    const { isAuthenticated, reloadUserAction, loadSchoolAndCompanyAction } =
        useAuthAction();

    useEffect(() => {
        if (isAuthenticated) {
            reloadUserAction();
            loadSchoolAndCompanyAction();
        }
    }, [isAuthenticated]);

    return <RootRoutes isAuthenticated={isAuthenticated} />;
}

export default App;
