import React, {
    FC,
    PropsWithChildren,
    createContext,
    useContext,
    useReducer,
} from "react";
import { User } from "../apis";
import { APPC, ENUM, LSC, UTIL } from "../config";

type AuthContextAction =
    | { type: ENUM.AuthContextAction.LOGIN_SUCCESS; payload: User }
    | { type: ENUM.AuthContextAction.RELOAD_USER; payload: User }
    | { type: ENUM.AuthContextAction.LOGOUT };

type GrantType = {
    isSuperAdmin: boolean;
    isInternshipCoordinatorTrainer: boolean;
    isInternshipReferenceTrainer: boolean;
    isPedagogicalReferenceTrainer: boolean;
    isSchoolDirector: boolean;
    isStudent: boolean;
    isInternshipCoordinator: boolean;
    isInternshipSupervisor: boolean;
    isInternshipTutor: boolean;
    isCompanyDirector: boolean;
    isSchoolAndCompany: boolean;
    hasSchoolAccess: boolean;
    hasCompanyAccess: boolean;
};

interface AuthContextState {
    isAuthenticated: boolean;
    user: User | null;
    grant: GrantType;
}

const authToken = localStorage.getItem(LSC.APP_TOKEN_KEY);
const userStringify = localStorage.getItem(LSC.APP_USER_KEY);

const getGrant = (user: User): GrantType => {
    const userType = user?.userType || "";
    const userTypeC = APPC.BE.User.USERTYPE;
    return {
        isSuperAdmin: userType === userTypeC.USERTYPE_SUPER_ADMIN,
        isInternshipCoordinatorTrainer:
            userType === userTypeC.USERTYPE_INTERNSHIP_COORDINATOR_TRAINER,
        isInternshipReferenceTrainer:
            userType === userTypeC.USERTYPE_INTERNSHIP_REFERENCE_TRAINER,
        isPedagogicalReferenceTrainer:
            userType === userTypeC.USERTYPE_PEDAGOGICAL_REFERENCE_TRAINER,
        isSchoolDirector: userType === userTypeC.USERTYPE_SCHOOL_DIRECTOR,
        isStudent: userType === userTypeC.USERTYPE_STUDENT,
        isInternshipCoordinator:
            userType === userTypeC.USERTYPE_INTERNSHIP_COORDINATOR,
        isInternshipSupervisor:
            userType === userTypeC.USERTYPE_INTERNSHIP_SUPERVISOR,
        isInternshipTutor: userType === userTypeC.USERTYPE_INTERNSHIP_TUTOR,
        isCompanyDirector: userType === userTypeC.USERTYPE_COMPANY_DIRECTOR,
        isSchoolAndCompany: userType === userTypeC.USERTYPE_SCHOOL_AND_COMPANY,
        hasSchoolAccess: UTIL.isSchoolType(user?.userType),
        hasCompanyAccess: UTIL.isCompanyType(user?.userType),
    };
};

const initialState: AuthContextState = {
    isAuthenticated: !!authToken,
    user: userStringify ? JSON.parse(userStringify) : null,
    grant: getGrant(userStringify ? JSON.parse(userStringify) : null),
};

export const AuthContext = createContext<{
    state: AuthContextState;
    dispatch: React.Dispatch<AuthContextAction>;
}>({
    state: initialState,
    dispatch: () => null,
});

function reducer(
    state: AuthContextState,
    action: AuthContextAction
): AuthContextState {
    switch (action.type) {
        case ENUM.AuthContextAction.LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload,
                grant: getGrant(action.payload),
            };

        case ENUM.AuthContextAction.RELOAD_USER:
            return {
                ...state,
                user: action.payload,
                grant: getGrant(action.payload),
            };

        case ENUM.AuthContextAction.LOGOUT:
            localStorage.removeItem(LSC.APP_TOKEN_KEY);
            localStorage.removeItem(LSC.APP_USER_KEY);
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            };

        default:
            return state;
    }
}

export const AuthProvider: FC<PropsWithChildren> = ({
    children,
}: PropsWithChildren): JSX.Element => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AuthContext.Provider value={{ state, dispatch }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthData = (): AuthContextState => {
    const context = useContext(AuthContext);
    if (context === null) {
        throw new Error("useAuthData must be used within a AuthProvider");
    }
    return context.state;
};
