import { BaseEntity } from "./BaseEntity";
import { Company } from "./Company";
import { School } from "./School";

export class User extends BaseEntity {
    public email: string = "";
    public roles: string[] = [];
    public userType: string = "";
    public plainPassword: string = "";
    public plainPasswordConfirm: string = "";
    public changePwdCode?: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public fullName: string = "";
    public imageName?: string = "";
    public dob?: string | null = null;
    public phone?: string = "";
    public nationality?: string = "";
    public address?: string = "";
    public zipCode?: string = "";
    public city?: string = "";
    public latitude?: string = "";
    public longitude?: string = "";
    public isActive?: boolean = false;
    public status?: string = "";
    public isOptin?: boolean = false;
    public optinTime?: string = "";
    public companies?: string[] | Company[] = [];
    public schools?: string[] | School[] = [];

    // Blameable
    public createdBy?: string = "";
    public updatedBy?: string = "";

    constructor(model: Partial<User> = {}) {
        super(model?.id);
        Object.assign(this, model);
    }
}

export type PUser = Partial<User>;
