import { FC, Suspense } from "react";
import { CropperRef, Cropper } from "react-advanced-cropper";
import { useTrans } from "../hooks";
import {
    Button,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
} from "@chakra-ui/react";
import { AppIcon } from "./AppIcon";
import { AppLoader } from "./AppLoader";
import "react-advanced-cropper/dist/style.css";

type AppCropperProps = {
    cropperRef: React.RefObject<CropperRef>;
    uploadedFile: File;
    fileTypeInfo: { ratio?: number | null };
    onDone: () => Promise<void> | void;
    onClose: () => void;
    isModal?: boolean;
    modalSize?: string;
};

export const AppCropper: FC<AppCropperProps> = ({
    cropperRef,
    uploadedFile,
    fileTypeInfo,
    onClose,
    onDone,
    isModal = true,
    modalSize = "xl",
}) => {
    const { t } = useTrans();

    const onRotate = async (isCcw = false) => {
        if (cropperRef?.current && uploadedFile) {
            cropperRef?.current?.rotateImage(isCcw ? 90 : -90);
        }
    };
    const onZoom = async (isZoomOut = false) => {
        if (cropperRef?.current && uploadedFile) {
            cropperRef?.current?.zoomImage(isZoomOut ? 0.5 : 2);
        }
    };

    const stencilOptions = {};
    if (fileTypeInfo.ratio) {
        stencilOptions["aspectRatio"] = fileTypeInfo.ratio;
    }

    const renderCropper = () => {
        return (
            <>
                <Suspense fallback={<AppLoader />}>
                    <Cropper
                        ref={cropperRef}
                        src={URL.createObjectURL(uploadedFile)}
                        className={"app-cropper"}
                        stencilProps={stencilOptions}
                    />
                </Suspense>
                <HStack justify={"space-between"} mt={4} wrap={"wrap"}>
                    <HStack spacing={2}>
                        <Button
                            variant={"outline"}
                            size={"sm"}
                            className="btn-icon-sm"
                            onClick={() => onZoom()}
                        >
                            <AppIcon name="icl-zoom-in" w="1rem" />
                        </Button>
                        <Button
                            variant={"outline"}
                            size={"sm"}
                            className="btn-icon-sm"
                            onClick={() => onZoom(true)}
                        >
                            <AppIcon name="icl-zoom-out" w="1rem" />
                        </Button>
                        <Button
                            variant={"outline"}
                            size={"sm"}
                            className="btn-icon-sm"
                            onClick={() => onRotate()}
                        >
                            <AppIcon name="icl-action-undo" w="1rem" />
                        </Button>
                        <Button
                            variant={"outline"}
                            size={"sm"}
                            className="btn-icon-sm"
                            onClick={() => onRotate(true)}
                        >
                            <AppIcon name="icl-action-redo" w="1rem" />
                        </Button>
                    </HStack>
                    <HStack spacing={2}>
                        <Button
                            variant={"outline"}
                            size={"sm"}
                            className="btn-icon-sm"
                            onClick={onClose}
                        >
                            <AppIcon name="icl-x" w="1rem" />
                        </Button>
                        <Button
                            variant={"primary"}
                            size={"sm"}
                            className="btn-icon-sm"
                            onClick={onDone}
                        >
                            <AppIcon name="icl-check-alt" w="1rem" />
                        </Button>
                    </HStack>
                </HStack>
            </>
        );
    };

    if (!isModal) {
        return renderCropper();
    }

    return (
        <Modal
            size={modalSize}
            isOpen={true}
            onClose={onClose}
            scrollBehavior={"inside"}
            closeOnOverlayClick={false}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t("com.AppCropper:text.title")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{renderCropper()}</ModalBody>
            </ModalContent>
        </Modal>
    );
};
