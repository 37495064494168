import { useEffect, useRef, useState } from "react";
import { Canceler } from "axios";
import { useAppTable, useFormContainerState, useTrans } from "../../../hooks";
import { VStack, HStack, useToast } from "@chakra-ui/react";
import { AppSearch } from "../../../containers";
import { AppPageHeader } from "../../../components";
import { RegionList as ListComponent } from "./RegionList";
import { Region as Entity, RegionApi as EntityApi } from "../../../apis";
import { APPC } from "../../../config";

export const RegionPage = () => {
    // hook
    const { t } = useTrans();
    const toast = useToast();
    const formContainerState = useFormContainerState();

    // state & const
    const [search, setSearch] = useState("");
    const [loadingList, setLoadingList] = useState(true);
    const [list, setList] = useState<Entity[]>([]);

    const cancelTokenSourcesRef = useRef<Canceler[]>([]);

    const {
        table,
        pageIndex,
        pagesCount,
        pageSize,
        sortColumn,
        sortOrder,
        setPagesCount,
    } = useAppTable("name");

    const paging = {
        enabled: true,
        pageSizes: APPC.PAGE_SIZES,
        pageSize,
        pageIndex,
        pagesCount,
    };

    // handler
    const fetchListData = async () => {
        setLoadingList(true);
        const params = {
            itemsPerPage: pageSize,
        };
        params[`order[${sortColumn}]`] = sortOrder;
        if (search) {
            params["region_department_search"] = search;
        }

        await EntityApi.getCollection<Entity>(pageIndex + 1, params, (c) => {
            cancelTokenSourcesRef.current.push(c);
        })
            .then(({ errorMessage, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    setPagesCount(Math.ceil(response.totalItems / pageSize));
                    setList(response.items);
                }
            })
            .finally(() => setLoadingList(false));
    };

    // useEffect
    useEffect(() => {
        if (!formContainerState.isOpen) {
            fetchListData();
        }
    }, [
        pageIndex,
        pageSize,
        sortColumn,
        sortOrder,
        search,
        formContainerState.isOpen,
    ]);

    return (
        <>
            <AppPageHeader title={t("padm.RegionPage:text.pageTitle")}>
                <HStack gap={4} py={{ base: 3.5, sm: 4, md: "1.125rem" }}>
                    <AppSearch onSearch={(value) => setSearch(value)} />
                </HStack>
            </AppPageHeader>
            <VStack p={6}>
                <ListComponent
                    table={table}
                    paging={paging}
                    list={list}
                    loadingList={loadingList}
                />
            </VStack>
        </>
    );
};
