import { FC } from "react";
import { useTrans } from "../../../hooks";
import { Text, Button, SimpleGrid } from "@chakra-ui/react";
import {
    DataType,
    IKaTableProps,
    ITableInstance,
    Table as KaTable,
    SortingMode,
} from "ka-table";
import { Column, PagingOptions } from "ka-table/models";
import { ICellTextProps, IDataRowProps } from "ka-table/props";
import { AppIcon, KaPageSizeSelector } from "../../../components";

export const RegionList: FC<{
    table: ITableInstance;
    paging: PagingOptions;
    list: any[];
    loadingList?: boolean;
}> = ({ table, paging, list, loadingList = false }) => {
    const { t } = useTrans();
    const columns: Column[] = [
        {
            key: "show-hide-details-row",
            width: 50,
        },
        {
            key: "name",
            title: t("ent.Region:name.label"),
            dataType: DataType.String,
        },
    ];

    const DetailsRow: React.FC<IDataRowProps> = ({ rowData }: any) => {
        return (
            <>
                <Text textStyle={"mediumLg"} ps={2} pb={2}>
                    {t("padm.RegionPage:text.detailRow.departments")}
                </Text>
                <SimpleGrid
                    columns={{ sm: 2, md: 3, lg: 4 }}
                    spacingX={10}
                    spacingY={5}
                    p={2}
                >
                    {rowData?.departments?.map((item) => (
                        <Text
                            key={item.id}
                            whiteSpace={"wrap"}
                            textStyle={"regularSm"}
                        >
                            {item.code} - {item.name}
                        </Text>
                    ))}
                </SimpleGrid>
            </>
        );
    };

    const DetailsButton = ({
        rowKeyValue,
        isDetailsRowShown,
    }: ICellTextProps) => {
        return (
            <Button
                onClick={() => {
                    isDetailsRowShown
                        ? table.hideDetailsRow(rowKeyValue)
                        : table.showDetailsRow(rowKeyValue);
                }}
                variant={"unstyled"}
                size={"xs"}
            >
                <AppIcon
                    name={
                        isDetailsRowShown
                            ? "icl-chevron-bottom-alt"
                            : "icl-chevron-right-alt"
                    }
                    w={"1rem"}
                />
            </Button>
        );
    };

    const kaTableProps: IKaTableProps = {
        table: table,
        columns: columns,
        data: list || [],
        loading: {
            enabled: loadingList,
        },
        paging: paging,
        rowKeyField: "id",
        sortingMode: SortingMode.SingleRemote,
        childComponents: {
            pagingSizes: {
                content: (props) => <KaPageSizeSelector {...props} />,
            },
            cellText: {
                content: (props: ICellTextProps) => {
                    switch (props?.column?.key) {
                        case "name":
                            return (
                                <Text textStyle={"mediumSm"} color={"black"}>
                                    {props?.rowData?.name}
                                </Text>
                            );
                        case "show-hide-details-row":
                            return <DetailsButton {...props} />;
                    }
                },
            },
            detailsRow: {
                elementAttributes: () => ({
                    style: {
                        backgroundColor: "var(--chakra-colors-primaryT95)",
                    },
                }),
                content: DetailsRow,
            },
        },
    };

    return <KaTable {...kaTableProps} />;
};
