import { APPC } from ".";

const schoolTypes = [
    APPC.BE.User.USERTYPE.USERTYPE_INTERNSHIP_COORDINATOR_TRAINER,
    APPC.BE.User.USERTYPE.USERTYPE_INTERNSHIP_REFERENCE_TRAINER,
    APPC.BE.User.USERTYPE.USERTYPE_PEDAGOGICAL_REFERENCE_TRAINER,
    APPC.BE.User.USERTYPE.USERTYPE_SCHOOL_DIRECTOR,
    APPC.BE.User.USERTYPE.USERTYPE_STUDENT,
    APPC.BE.User.USERTYPE.USERTYPE_SCHOOL_AND_COMPANY,
];
const companyTypes = [
    APPC.BE.User.USERTYPE.USERTYPE_INTERNSHIP_COORDINATOR,
    APPC.BE.User.USERTYPE.USERTYPE_INTERNSHIP_SUPERVISOR,
    APPC.BE.User.USERTYPE.USERTYPE_INTERNSHIP_TUTOR,
    APPC.BE.User.USERTYPE.USERTYPE_COMPANY_DIRECTOR,
    APPC.BE.User.USERTYPE.USERTYPE_SCHOOL_AND_COMPANY,
];
const multiSelectTypes = [
    APPC.BE.User.USERTYPE.USERTYPE_INTERNSHIP_COORDINATOR_TRAINER,
    APPC.BE.User.USERTYPE.USERTYPE_INTERNSHIP_COORDINATOR,
    APPC.BE.User.USERTYPE.USERTYPE_SCHOOL_AND_COMPANY,
];
const userCreateTypes = [
    APPC.BE.User.USERTYPE.USERTYPE_INTERNSHIP_COORDINATOR_TRAINER,
    APPC.BE.User.USERTYPE.USERTYPE_INTERNSHIP_COORDINATOR,
    APPC.BE.User.USERTYPE.USERTYPE_SCHOOL_AND_COMPANY,
    APPC.BE.User.USERTYPE.USERTYPE_SUPER_ADMIN,
];
const schoolUserCreateTypes = [
    APPC.BE.User.USERTYPE.USERTYPE_INTERNSHIP_REFERENCE_TRAINER,
    APPC.BE.User.USERTYPE.USERTYPE_PEDAGOGICAL_REFERENCE_TRAINER,
    APPC.BE.User.USERTYPE.USERTYPE_SCHOOL_DIRECTOR,
    APPC.BE.User.USERTYPE.USERTYPE_STUDENT,
];
const companyUserCreateTypes = [
    APPC.BE.User.USERTYPE.USERTYPE_INTERNSHIP_SUPERVISOR,
    APPC.BE.User.USERTYPE.USERTYPE_INTERNSHIP_TUTOR,
    APPC.BE.User.USERTYPE.USERTYPE_COMPANY_DIRECTOR,
];
const superAdminUserCreateTypes = [
    APPC.BE.User.USERTYPE.USERTYPE_INTERNSHIP_COORDINATOR_TRAINER,
    APPC.BE.User.USERTYPE.USERTYPE_INTERNSHIP_COORDINATOR,
    APPC.BE.User.USERTYPE.USERTYPE_SCHOOL_AND_COMPANY,
    APPC.BE.User.USERTYPE.USERTYPE_SUPER_ADMIN,
];

export const isSchoolType = (type: string = ""): boolean => {
    return schoolTypes.includes(type);
};

export const isCompanyType = (type: string = ""): boolean => {
    return companyTypes.includes(type);
};

export const isSchoolCompanyMultiSelect = (type: string = ""): boolean => {
    return multiSelectTypes.includes(type);
};

export const isUserCreate = (type: string = ""): boolean => {
    return userCreateTypes.includes(type);
};

export const userCreateTypeAllow = (
    type: string = "",
    isSchool: boolean = false
): string[] => {
    if (
        type ===
            APPC.BE.User.USERTYPE.USERTYPE_INTERNSHIP_COORDINATOR_TRAINER ||
        (type === APPC.BE.User.USERTYPE.USERTYPE_SCHOOL_AND_COMPANY && isSchool)
    ) {
        return schoolUserCreateTypes;
    } else if (
        type === APPC.BE.User.USERTYPE.USERTYPE_INTERNSHIP_COORDINATOR ||
        (type === APPC.BE.User.USERTYPE.USERTYPE_SCHOOL_AND_COMPANY &&
            !isSchool)
    ) {
        return companyUserCreateTypes;
    } else if (type === APPC.BE.User.USERTYPE.USERTYPE_SUPER_ADMIN) {
        return superAdminUserCreateTypes;
    }
    return [];
};
