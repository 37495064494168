import { useEffect, useState } from "react";
import { useTrans } from "../hooks";
import {
    Box,
    Button,
    Checkbox,
    Flex,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    useDisclosure,
    FormLabel,
} from "@chakra-ui/react";
import { AppIcon } from "./AppIcon";

export interface AppMultiSelectorOption {
    value: string;
    label: string;
}

export type AppMultiSelectorOptionType = {
    data: AppMultiSelectorOption[];
    totalItems: number;
};

interface AppMultiSelectorProps {
    loadOptions: (
        page: number,
        search: string,
        pageSize: number
    ) => Promise<AppMultiSelectorOptionType>;
    onSelect: (data: AppMultiSelectorOption[]) => void;
    selectedOpt: AppMultiSelectorOption[];
    subject: string;
    triggerElement?: JSX.Element;
    isModal?: boolean;
    isSearch?: boolean;
    pageSize?: number;
    selectorRows?: number;
    selectedRows?: number;
    maxSelectOptions?: number;
}

export const AppMultiSelector = ({
    loadOptions,
    onSelect,
    selectedOpt,
    subject,
    triggerElement,
    isModal = false,
    isSearch = true,
    pageSize = 30,
    selectorRows = 5,
    selectedRows = 3,
    maxSelectOptions,
}: AppMultiSelectorProps) => {
    const { t } = useTrans();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [value, setValue] = useState("");
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(true);
    const [loadedPage, setLoadedPage] = useState(0);
    const [page, setPage] = useState(1);
    const [data, setData] = useState<AppMultiSelectorOption[]>([]);
    const [selectedOptions, setSelectedOptions] =
        useState<AppMultiSelectorOption[]>(selectedOpt);

    const onSelectHandler = () => {
        onSelect(selectedOptions);
        onClose();
    };

    const handleSearch = (v: string) => {
        if (v !== value) {
            if (page !== 1) {
                setPage(1);
            }
            setValue(v);
        }
    };

    const handleCheckboxChange = (value: string) => {
        setSelectedOptions((pso) => {
            const prev = [...pso];
            const foundIndex = prev.findIndex((val) => val.value === value);
            if (foundIndex > -1) {
                prev.splice(foundIndex, 1);
                return prev;
            }
            const newElement = data.find((val) => val.value === value);
            if (newElement) {
                return [...prev, newElement];
            }
            return prev;
        });
    };

    const handleSelectAll = () => {
        if (selectedOptions.length === data.length) {
            setSelectedOptions([]);
        } else {
            setSelectedOptions(data);
        }
    };

    const isIndeterminate = () => {
        return (
            selectedOptions.length > 0 && selectedOptions.length < data.length
        );
    };

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        if (
            scrollTop + clientHeight >= scrollHeight &&
            data.length < totalItems
        ) {
            setPage(loadedPage + 1);
        }
    };

    useEffect(() => {
        if (!isOpen && isModal) {
            return;
        }
        setLoading(true);
        loadOptions(page, value, pageSize).then((result) => {
            page === 1
                ? setData(result.data)
                : setData([...data, ...result.data]);
            setTotalItems(result.totalItems);
            setLoadedPage(page);
            setLoading(false);
        });
    }, [page, value, isOpen]);

    const renderSelector = () => (
        <Flex
            direction="column"
            width={"full"}
            justifyContent={"space-between"}
            gap={6}
            p={4}
            border={"1px solid var(--chakra-colors-primaryT80)"}
            borderRadius={"0.25rem"}
        >
            {isSearch && (
                <InputGroup minW={"11.25rem"}>
                    <InputLeftElement pointerEvents="none">
                        <AppIcon name="ics-search" />
                    </InputLeftElement>
                    <Input
                        type="text"
                        placeholder={t(
                            "com.AppMultiSelector:search.placeholder"
                        )}
                        value={value}
                        onChange={(e) => handleSearch(e.currentTarget.value)}
                        maxLength={50}
                    />
                    <InputRightElement>
                        <Button
                            display={value ? "block" : "none"}
                            variant={"transparent"}
                            size={"sm"}
                            className="btn-icon-sm"
                            onClick={() => handleSearch("")}
                        >
                            <AppIcon name="icl-x" w="1rem" />
                        </Button>
                    </InputRightElement>
                </InputGroup>
            )}
            <Flex
                direction="column"
                width={"full"}
                justifyContent={"space-between"}
                border={"1px solid var(--chakra-colors-primaryT80)"}
                borderRadius={"0.25rem"}
            >
                <HStack
                    h={"50px"}
                    px={3}
                    gap={3}
                    bg={"var(--chakra-colors-primaryT95)"}
                    borderTopLeftRadius={"0.25rem"}
                    borderTopRightRadius={"0.25rem"}
                    borderBottom={"1px solid var(--chakra-colors-primaryT80)"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    {!maxSelectOptions && (
                        <Checkbox
                            isChecked={selectedOptions.length === data.length}
                            isIndeterminate={isIndeterminate()}
                            onChange={handleSelectAll}
                        />
                    )}
                    <Box flexGrow={1}>
                        <Text
                            textStyle={"regularMd"}
                        >{`${subject} (${selectedOptions.length}/${totalItems})`}</Text>
                    </Box>
                </HStack>
                <Flex
                    direction="column"
                    width={"full"}
                    maxH={`${selectorRows * 40}px`}
                    my={5}
                    gap={3}
                    overflowY="auto"
                    sx={{ scrollbarWidth: "thin" }}
                    onScroll={handleScroll}
                >
                    {data.map((opt) => (
                        <HStack
                            key={opt.value}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            px={3}
                            gap={3}
                        >
                            <Checkbox
                                id={`${opt.value}`}
                                isChecked={
                                    selectedOptions.findIndex(
                                        (val) => val.value === opt.value
                                    ) > -1
                                }
                                onChange={() => handleCheckboxChange(opt.value)}
                                isDisabled={
                                    selectedOptions.findIndex(
                                        (val) => val.value === opt.value
                                    ) === -1 &&
                                    Number(maxSelectOptions) ==
                                        selectedOptions.length
                                }
                            />
                            <Box flexGrow={1}>
                                <FormLabel
                                    htmlFor={`${opt.value}`}
                                    style={{ cursor: "pointer" }}
                                >
                                    {opt.label}
                                </FormLabel>
                            </Box>
                        </HStack>
                    ))}
                </Flex>
                <HStack
                    h={"50px"}
                    px={3}
                    borderTop={"1px solid var(--chakra-colors-primaryT80)"}
                    justifyContent={"space-between"}
                    position={"relative"}
                >
                    <Text>{`${t("com.AppMultiSelector:text.showing")} ${
                        data.length
                    } ${t(
                        "com.AppMultiSelector:text.of"
                    )} ${totalItems}`}</Text>
                    {/* <HStack gap={3}>
                        <Link href="">
                            {t("com.AppMultiSelector:link.selectAll")}
                        </Link>
                        <Center height="1.3rem">
                            <Divider
                                orientation="vertical"
                                borderColor={"var(--chakra-colors-greyT80)"}
                            />
                        </Center>
                        <Link href="">
                            {t("com.AppMultiSelector:link.clearAll")}
                        </Link>
                    </HStack> */}
                    {loading && (
                        <Text
                            position={"absolute"}
                            bottom={"55px"}
                            bg={"var(--chakra-colors-white)"}
                        >
                            {t("com.AppMultiSelector:text.loading")}
                        </Text>
                    )}
                </HStack>
            </Flex>
            <HStack justifyContent={"flex-end"}>
                <Button onClick={() => onSelectHandler()} size={"sm"}>
                    {t("com.AppMultiSelector:button.select")}
                </Button>
            </HStack>
        </Flex>
    );

    const renderWithModal = () => (
        <>
            <Box
                border={"1px solid var(--chakra-colors-greyT65)"}
                borderRadius={"0.25rem"}
                p={3}
            >
                <Flex
                    direction="column"
                    width={"full"}
                    maxH={`${selectedRows * 44 - 12}px`}
                    gap={3}
                    overflowY="auto"
                    sx={{ scrollbarWidth: "thin" }}
                >
                    {selectedOptions.map((opt, index) => (
                        <Box key={index}>
                            <Text
                                noOfLines={1}
                                textStyle={"mediumSm"}
                                bg={"var(--chakra-colors-primaryT90)"}
                                color={"var(--chakra-colors-contentT40)"}
                                px={2}
                                lineHeight={"2rem"}
                                borderRadius={"0.25rem"}
                            >
                                {opt.label}
                            </Text>
                        </Box>
                    ))}
                </Flex>
                <Box
                    onClick={onOpen}
                    cursor={"pointer"}
                    mt={selectedOptions.length > 0 ? 3 : 0}
                >
                    {triggerElement ? (
                        triggerElement
                    ) : (
                        <Button variant={"secondary"} size={"sm"}>
                            {t("com.AppMultiSelector:button.select")}
                        </Button>
                    )}
                </Box>
            </Box>
            {isOpen && (
                <Modal isOpen={isOpen} onClose={onClose} size={"lg"}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{`Select ${subject}`}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody p={6}>{renderSelector()}</ModalBody>
                    </ModalContent>
                </Modal>
            )}
        </>
    );

    return isModal ? renderWithModal() : renderSelector();
};
