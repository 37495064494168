import { BaseEntity } from "./BaseEntity";
import { Company } from "./Company";
import { School } from "./School";

export class Category extends BaseEntity {
    public name: string = "";
    public type: string = "";
    public companies?: string[] | Company[] = [];
    public schools?: string[] | School[] = [];

    constructor(model: Partial<Category> = {}) {
        super(model?.id);
        Object.assign(this, model);
    }
}

export type PCategory = Partial<Category>;
