import { FC, useEffect, useState } from "react";
import { FormContainerStateReturnType, useTrans } from "../../../hooks";
import {
    VStack,
    Input,
    Switch,
    Textarea,
    Box,
    Stack,
    useToast,
} from "@chakra-ui/react";
import {
    School as Entity,
    SchoolApi as EntityApi,
    Category,
    CategoryApi,
    DepartmentApi,
    Region,
    RegionApi,
    UploadApi,
} from "../../../apis";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema";
import { AppFormContainer } from "../../../containers";
import {
    AppSelect,
    AppFormControl,
    AppSelectOption,
    AppImageUploader,
} from "../../../components";
import { APPC } from "../../../config";
import { SchoolDetailView } from "./SchoolDetailView";

export const SchoolForm: FC<{
    formContainerState: FormContainerStateReturnType;
    isDetailPage: boolean;
}> = ({ formContainerState, isDetailPage }) => {
    // hook
    const { t } = useTrans();
    const toast = useToast();

    // state & const
    const isEditMode: boolean = !!formContainerState.entityId;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<Entity>(new Entity());
    const [loadingCategories, setLoadingCategories] = useState(false);
    const [categoryOptions, setCategoryOptions] = useState<AppSelectOption[]>(
        []
    );
    const [selectedCategory, setSelectedCategory] =
        useState<AppSelectOption | null>(null);
    const [totalCategories, setTotalCategories] = useState(0);
    const [categoryPage, setCategoryPage] = useState(1);
    const [categorySearch, setCategorySearch] = useState("");
    const [loadingRegions, setLoadingRegions] = useState(false);
    const [regions, setRegions] = useState<Region[]>([]);
    const [regionOptions, setRegionOptions] = useState<AppSelectOption[]>([]);
    const [selectedRegion, setSelectedRegion] =
        useState<AppSelectOption | null>(null);
    const [departmentOptions, setDepartmentOptions] = useState<
        AppSelectOption[]
    >([]);
    const [selectedDepartment, setSelectedDepartment] =
        useState<AppSelectOption | null>(null);
    const [imageNameFile, setImageNameFile] = useState<File | undefined>(
        undefined
    );

    const {
        handleSubmit,
        register,
        reset,
        setValue,
        control,
        formState: { errors },
    } = useForm<Entity>({
        resolver: yupResolver(schema(t)),
        mode: "onSubmit",
    });

    // handler
    const onSubmitHandler = async (formData: Entity) => {
        setLoading(true);
        if (imageNameFile) {
            const uploadFormData = new FormData();
            uploadFormData.set(
                "fileType",
                APPC.BE.Upload.FILETYPE.FILETYPE_SCHOOL_POSTER
            );
            uploadFormData.set("file", imageNameFile);

            await UploadApi.upload<{ fileName: string }, FormData>(
                uploadFormData
            ).then(({ response, errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    formData.imageName = response.fileName;
                }
            });
        }
        EntityApi.createOrUpdate<Entity, Entity>(
            formContainerState.entityId,
            formData
        )
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    toast({
                        title: t("cmn:message.save.success"),
                        status: "success",
                    });
                    reset();
                    formContainerState.close();
                }
            })
            .finally(() => setLoading(false));
    };

    const loadCategoryOptions = () => {
        setLoadingCategories(true);
        const params = {
            [`order[name]`]: "asc",
            type: APPC.BE.Category.TYPE.TYPE_SCHOOL,
        };

        if (categorySearch) {
            params["name"] = categorySearch;
        }

        CategoryApi.getCollection<Category>(categoryPage, params)
            .then(({ response }) => {
                if (response !== null) {
                    setTotalCategories(response.totalItems);
                    const newOptions =
                        response.items?.map((e) => ({
                            value: CategoryApi.toResourceIRI(e.id),
                            label: e.name,
                        })) || [];
                    categoryPage === 1
                        ? setCategoryOptions(newOptions)
                        : setCategoryOptions((prevOptions) => [
                              ...prevOptions,
                              ...newOptions,
                          ]);
                }
            })
            .finally(() => {
                setLoadingCategories(false);
            });
    };

    const loadRegionOptions = () => {
        setLoadingRegions(true);
        RegionApi.getCollection<Region>(1, {
            [`order[name]`]: "asc",
            pagination: false,
        })
            .then(({ response }) => {
                setRegions(response?.items || []);
                setRegionOptions(
                    response?.items?.map((e) => ({
                        value: RegionApi.toResourceIRI(e.id),
                        label: e.name,
                    })) || []
                );
                if (data?.region) {
                    const currentRegions = response?.items?.filter(
                        (e) => e.id === (data?.region as Region)?.id
                    );
                    if (currentRegions?.[0]?.departments) {
                        setDepartmentOptions(
                            currentRegions?.[0].departments?.map((e) => ({
                                value: DepartmentApi.toResourceIRI(e.id),
                                label: e.name,
                            })) || []
                        );
                    }
                }
            })
            .finally(() => setLoadingRegions(false));
    };

    // useEffect
    useEffect(() => {
        loadCategoryOptions();
    }, [categoryPage, categorySearch]);

    useEffect(() => {
        loadRegionOptions();
    }, []);

    useEffect(() => {
        if (selectedRegion) {
            const currentRegions = regions?.filter(
                (e) => e["@id"] === selectedRegion?.value
            );
            if (currentRegions?.[0]?.departments) {
                setDepartmentOptions(
                    currentRegions?.[0].departments?.map((e) => ({
                        value: DepartmentApi.toResourceIRI(e.id),
                        label: e.name,
                    })) || []
                );
            }
        }
    }, [selectedRegion]);

    useEffect(() => {
        if (!formContainerState.entityId) {
            reset();
            setData(new Entity());
            setLoading(false);
            return;
        }

        EntityApi.getItem<Entity>(formContainerState.entityId)
            .then(({ errorMessage, isNotFound, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (isNotFound) {
                    toast({
                        title: t("cmn:message.entityNotFound"),
                        status: "error",
                    });
                } else if (response !== null) {
                    reset();
                    if (
                        response.category &&
                        typeof response.category !== "string"
                    ) {
                        setSelectedCategory({
                            value: CategoryApi.toResourceIRI(
                                response.category.id
                            ),
                            label: response.category.name,
                        });
                    }
                    if (
                        response.region &&
                        typeof response.region !== "string"
                    ) {
                        setSelectedRegion({
                            value: RegionApi.toResourceIRI(response.region.id),
                            label: response.region.name,
                        });
                    }
                    if (
                        response.department &&
                        typeof response.department !== "string"
                    ) {
                        setSelectedDepartment({
                            value: DepartmentApi.toResourceIRI(
                                response.department.id
                            ),
                            label: response.department.name,
                        });
                    }
                    setData(response);
                }
            })
            .finally(() => setLoading(false));
    }, [formContainerState.entityId]);

    // render
    const renderForm = () => {
        return (
            <form
                id="app-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(onSubmitHandler)();
                }}
            >
                <VStack gap={5} alignItems={"stretch"}>
                    <Stack
                        w={"full"}
                        gap={5}
                        direction={{ base: "column", sm: "column", md: "row" }}
                    >
                        <Box w={{ base: "full", sm: "full", md: "50%" }}>
                            <VStack justifyContent={"space-between"} gap={5}>
                                <AppFormControl
                                    label={t("ent.School:name.label")}
                                    isInvalid={!!errors.name}
                                    message={errors.name?.message}
                                    isLoading={loading}
                                >
                                    <Input
                                        {...register("name")}
                                        type="text"
                                        placeholder={t(
                                            "ent.School:name.placeholder"
                                        )}
                                        defaultValue={data.name}
                                    />
                                </AppFormControl>
                                <AppFormControl
                                    label={t("ent.School:category.label")}
                                    isLoading={loading}
                                >
                                    <Controller
                                        control={control}
                                        name="category"
                                        // defaultValue={CategoryApi.toResourceIRI(
                                        //     (data?.category as Category)?.id
                                        // )}
                                        render={({ field }) => (
                                            <AppSelect<AppSelectOption>
                                                name={field.name}
                                                isClearable
                                                isLoading={loadingCategories}
                                                options={categoryOptions}
                                                value={selectedCategory}
                                                placeholder={t(
                                                    "ent.School:category.placeholder"
                                                )}
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e?.value || null
                                                    );
                                                    setSelectedCategory(
                                                        e as AppSelectOption
                                                    );
                                                }}
                                                onMenuScrollToBottom={() => {
                                                    if (
                                                        categoryOptions.length >=
                                                        totalCategories
                                                    ) {
                                                        return;
                                                    }
                                                    setCategoryPage(
                                                        (prevPage) =>
                                                            prevPage + 1
                                                    );
                                                }}
                                                onInputChange={(
                                                    inputValue: string
                                                ) => {
                                                    if (
                                                        inputValue !==
                                                        categorySearch
                                                    ) {
                                                        setCategorySearch(
                                                            inputValue
                                                        );
                                                        if (
                                                            categoryPage !== 1
                                                        ) {
                                                            setCategoryPage(1);
                                                        }
                                                        setCategoryOptions([]);
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </AppFormControl>
                                <AppFormControl
                                    label={t("ent.School:region.label")}
                                    isLoading={loading}
                                >
                                    <Controller
                                        control={control}
                                        name="region"
                                        // defaultValue={RegionApi.toResourceIRI(
                                        //     (data?.region as Region)?.id
                                        // )}
                                        render={({ field }) => (
                                            <AppSelect<AppSelectOption>
                                                name={field.name}
                                                isClearable
                                                isLoading={loadingRegions}
                                                options={regionOptions}
                                                value={selectedRegion || null}
                                                placeholder={t(
                                                    "ent.School:region.placeholder"
                                                )}
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e?.value || null
                                                    );
                                                    setSelectedRegion(
                                                        e as AppSelectOption
                                                    );
                                                    setSelectedDepartment(null);
                                                    setValue(
                                                        "department",
                                                        undefined
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </AppFormControl>
                                <AppFormControl
                                    label={t("ent.School:department.label")}
                                    isLoading={loading}
                                >
                                    <Controller
                                        control={control}
                                        name="department"
                                        // defaultValue={DepartmentApi.toResourceIRI(
                                        //     (data?.department as Department)?.id
                                        // )}
                                        render={({ field }) => (
                                            <AppSelect<AppSelectOption>
                                                name={field.name}
                                                isClearable
                                                isLoading={loadingRegions}
                                                options={departmentOptions}
                                                value={
                                                    selectedDepartment || null
                                                }
                                                placeholder={t(
                                                    "ent.School:department.placeholder"
                                                )}
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e?.value || null
                                                    );
                                                    setSelectedDepartment(
                                                        e as AppSelectOption
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </AppFormControl>
                            </VStack>
                        </Box>
                        <Box w={{ base: "full", sm: "full", md: "50%" }}>
                            <AppFormControl
                                label={t("ent.School:imageName.label")}
                                isLoading={loading}
                            >
                                <AppImageUploader
                                    fileType={
                                        APPC.BE.Upload.FILETYPE
                                            .FILETYPE_SCHOOL_POSTER
                                    }
                                    fileName={data?.imageName}
                                    onDone={(file) => setImageNameFile(file)}
                                />
                            </AppFormControl>
                        </Box>
                    </Stack>
                    <AppFormControl
                        label={t("ent.School:description.label")}
                        isLoading={loading}
                    >
                        <Textarea
                            {...register("description")}
                            placeholder={t(
                                "ent.School:description.placeholder"
                            )}
                            defaultValue={data.description}
                        />
                    </AppFormControl>
                    <Stack
                        gap={5}
                        direction={{ base: "column", sm: "column", md: "row" }}
                    >
                        <AppFormControl
                            label={t("ent.School:externalCode.label")}
                            isLoading={loading}
                        >
                            <Input
                                {...register("externalCode")}
                                type="text"
                                placeholder={t(
                                    "ent.School:externalCode.placeholder"
                                )}
                                defaultValue={data.externalCode}
                            />
                        </AppFormControl>
                        <AppFormControl
                            label={t("ent.School:siret.label")}
                            isLoading={loading}
                        >
                            <Input
                                {...register("siret")}
                                type="text"
                                placeholder={t("ent.School:siret.placeholder")}
                                defaultValue={data.siret}
                            />
                        </AppFormControl>
                        <AppFormControl
                            label={t("ent.School:phone.label")}
                            isLoading={loading}
                        >
                            <Input
                                {...register("phone")}
                                type="text"
                                placeholder={t("ent.School:phone.placeholder")}
                                defaultValue={data.phone}
                            />
                        </AppFormControl>
                    </Stack>
                    <Stack
                        w={"full"}
                        gap={5}
                        direction={{ base: "column", sm: "column", md: "row" }}
                    >
                        <Box w={{ base: "full", sm: "full", md: "50%" }}>
                            <AppFormControl
                                label={t("ent.School:address.label")}
                                isLoading={loading}
                            >
                                <Textarea
                                    {...register("address")}
                                    h="131px"
                                    resize={"none"}
                                    placeholder={t(
                                        "ent.School:address.placeholder"
                                    )}
                                    defaultValue={data.address}
                                />
                            </AppFormControl>
                        </Box>
                        <Box w={{ base: "full", sm: "full", md: "50%" }}>
                            <VStack justifyContent={"space-between"} gap={5}>
                                <AppFormControl
                                    label={t("ent.School:zipCode.label")}
                                    isInvalid={!!errors.zipCode}
                                    message={errors.zipCode?.message}
                                    isLoading={loading}
                                >
                                    <Input
                                        {...register("zipCode")}
                                        type="text"
                                        placeholder={t(
                                            "ent.School:zipCode.placeholder"
                                        )}
                                        defaultValue={data.zipCode}
                                    />
                                </AppFormControl>
                                <AppFormControl
                                    label={t("ent.School:city.label")}
                                    isLoading={loading}
                                >
                                    <Input
                                        {...register("city")}
                                        type="text"
                                        placeholder={t(
                                            "ent.School:city.placeholder"
                                        )}
                                        defaultValue={data.city}
                                    />
                                </AppFormControl>
                            </VStack>
                        </Box>
                    </Stack>
                    <Stack
                        gap={5}
                        direction={{ base: "column", sm: "column", md: "row" }}
                    >
                        <AppFormControl
                            label={t("ent.School:latitude.label")}
                            isInvalid={!!errors.latitude}
                            message={errors.latitude?.message}
                            isLoading={loading}
                        >
                            <Input
                                {...register("latitude")}
                                type="text"
                                placeholder={t(
                                    "ent.School:latitude.placeholder"
                                )}
                                defaultValue={data.latitude}
                            />
                        </AppFormControl>
                        <AppFormControl
                            label={t("ent.School:longitude.label")}
                            isInvalid={!!errors.longitude}
                            message={errors.longitude?.message}
                            isLoading={loading}
                        >
                            <Input
                                {...register("longitude")}
                                type="text"
                                placeholder={t(
                                    "ent.School:longitude.placeholder"
                                )}
                                defaultValue={data.longitude}
                            />
                        </AppFormControl>
                    </Stack>
                    <AppFormControl
                        label={t("ent.School:isActive.label")}
                        isInvalid={!!errors.isActive}
                        message={errors.isActive?.message}
                        isLoading={loading}
                    >
                        <Switch
                            {...register("isActive")}
                            defaultChecked={isEditMode ? !!data.isActive : true}
                        />
                    </AppFormControl>
                </VStack>
            </form>
        );
    };

    const renderDetail = () => {
        return <SchoolDetailView data={data} />;
    };

    return (
        <AppFormContainer
            title={t("padm.SchoolForm:text.pageTitle")}
            state={formContainerState}
            type={APPC.FORM_CONTAINER}
            size="xl"
        >
            {isDetailPage ? renderDetail() : renderForm()}
        </AppFormContainer>
    );
};
