import { useNavigate } from "react-router-dom";
import { useTrans } from "../hooks";
import { Box } from "@chakra-ui/react";
import { AppSelect, AppSelectOption } from "./AppSelect";
import { CompanyApi, SchoolApi } from "../apis";
import { useRecoilState, useRecoilValue } from "recoil";
import {
    ActiveSchoolOrCompanyAtom,
    UserCompanyListAtom,
    UserSchoolListAtom,
} from "../atoms";
import { LSC } from "../config";

export const AppSchoolCompanySelector = () => {
    const { t } = useTrans();
    const navigate = useNavigate();

    const schoolData = useRecoilValue(UserSchoolListAtom);
    const schoolOptions = schoolData?.map((e) => {
        return {
            value: SchoolApi.toResourceIRI(e.id),
            label: e.name,
        };
    });
    const companyData = useRecoilValue(UserCompanyListAtom);
    const companyOptions = companyData?.map((e) => {
        return {
            value: CompanyApi.toResourceIRI(e.id),
            label: e.name,
        };
    });
    const [activeSchoolOrCompany, setActiveSchoolOrCompany] = useRecoilState(
        ActiveSchoolOrCompanyAtom
    );

    const groupedOptions: { label: string; options: AppSelectOption[] }[] = [];
    if (schoolOptions) {
        groupedOptions.push({
            label: t("com.AppSchoolCompanySelector:text.schools"),
            options: [...schoolOptions],
        });
    }
    if (companyOptions) {
        groupedOptions.push({
            label: t("com.AppSchoolCompanySelector:text.companies"),
            options: [...companyOptions],
        });
    }

    const onChange = (value: string) => {
        localStorage.setItem(LSC.APP_SEL_SC, `${value}`);
        const sel = [...schoolData, ...companyData]?.filter((d) =>
            d?.id === activeSchoolOrCompany?.id ? d : false
        );
        if (sel) {
            setActiveSchoolOrCompany(sel[0]);
            navigate(0);
        }
    };
    const controlLabel: string[] = [];
    if (schoolOptions?.length > 0) {
        controlLabel.push(t("com.AppSchoolCompanySelector:text.schools"));
    }
    if (companyOptions?.length > 0) {
        controlLabel.push(t("com.AppSchoolCompanySelector:text.companies"));
    }

    return (
        <Box>
            <AppSelect<AppSelectOption>
                options={groupedOptions}
                onChange={(e) => onChange(`${e?.value}`)}
                value={[...schoolOptions, ...companyOptions]?.filter(
                    (d) => d?.value === activeSchoolOrCompany?.["@id"]
                )}
                placeholder={`${t(
                    "com.AppSchoolCompanySelector:placeholder.select"
                )} ${controlLabel.join(" / ")}`}
                isClearable={false}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused
                            ? "var(--chakra-colors-primary)"
                            : "var(--chakra-colors-greyT65)",
                        height: "50px",
                        minWidth: "350px",
                        ":hover": {
                            borderColor: state.isFocused
                                ? "var(--chakra-colors-primary)"
                                : "var(--chakra-colors-greyT40)",
                        },
                    }),
                }}
            />
            <span
                style={{
                    fontSize: "8px",
                    position: "absolute",
                    top: "5px",
                    background: "var(--chakra-colors-white)",
                    padding: "0 8px",
                    marginLeft: "8px",
                    textTransform: "uppercase",
                }}
            >
                {controlLabel.join(" / ")}
            </span>
        </Box>
    );
};
