import { BaseEntity } from "./BaseEntity";
import { Company } from "./Company";
import { Service } from "./Service";

export class ServiceGroup extends BaseEntity {
    public name: string = "";
    public address: string = "";
    public zipCode?: string = "";
    public city?: string = "";
    public latitude?: string = "";
    public longitude?: string = "";
    public services?: string[] | Service[] = [];
    public company: string | Company = "";

    constructor(model: Partial<ServiceGroup> = {}) {
        super(model?.id);
        Object.assign(this, model);
    }
}

export type PServiceGroup = Partial<ServiceGroup>;
