import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts";
import { useToast } from "@chakra-ui/react";
import { AuthApi, Company, CompanyApi, School, SchoolApi, User } from "../apis";
import { useSetRecoilState } from "recoil";
import {
    ActiveSchoolOrCompanyAtom,
    UserCompanyListAtom,
    UserSchoolListAtom,
} from "../atoms";
import { cPath, ENUM, LSC } from "../config";

type AuthActionType = {
    isAuthenticated: boolean;
    setAuthTokenAction: (token: string) => void;
    setUserAction: (user: User) => void;
    loginAction: (payload: User) => void;
    tokenLoginAction: (token: string) => void;
    logoutAction: () => void;
    reloadUserAction: () => Promise<void>;
    loadSchoolAndCompanyAction: () => Promise<void>;
};

export function useAuthAction(): AuthActionType {
    // hooks
    const navigate = useNavigate();
    const toast = useToast();
    // const { changeLocale } = useTrans();

    // contexts
    const { state, dispatch } = useContext(AuthContext);

    // state
    const { isAuthenticated, grant } = state;

    const setUserSchoolList = useSetRecoilState(UserSchoolListAtom);
    const setUserCompanyList = useSetRecoilState(UserCompanyListAtom);
    const setActiveSchoolOrCompany = useSetRecoilState(
        ActiveSchoolOrCompanyAtom
    );

    const setAuthTokenAction = (token: string) => {
        localStorage.setItem(LSC.APP_TOKEN_KEY, token);
    };

    const setUserAction = (user: User) => {
        localStorage.setItem(LSC.APP_USER_KEY, JSON.stringify(user));
    };

    const loginAction = (payload: User) => {
        setUserAction(payload);

        dispatch({
            type: ENUM.AuthContextAction.LOGIN_SUCCESS,
            payload,
        });
    };

    const tokenLoginAction = (token: string) => {
        setAuthTokenAction(token);
        AuthApi.me<User>().then(({ response, errorMessage }) => {
            if (errorMessage) {
                toast({ title: errorMessage, status: "error" });
            } else if (response !== null) {
                loginAction(response);
            }
        });
    };

    const logoutAction = () => {
        const pToken = localStorage.getItem(LSC.APP_TOKEN_P_KEY);
        if (pToken) {
            tokenLoginAction(pToken);
            setTimeout(() => {
                localStorage.removeItem(LSC.APP_TOKEN_P_KEY);
                document.location.href = cPath("ADMIN.DASHBOARD_PAGE");
            }, 500);
        } else {
            setUserSchoolList([]);
            setUserCompanyList([]);
            setActiveSchoolOrCompany(null);
            dispatch({ type: ENUM.AuthContextAction.LOGOUT });
            navigate("/");
        }
    };

    const reloadUserAction = async () =>
        AuthApi.me<User>().then(({ response, errorMessage }) => {
            if (errorMessage) {
                toast({ title: errorMessage, status: "error" });
            } else if (response !== null) {
                setUserAction(response);

                dispatch({
                    type: ENUM.AuthContextAction.RELOAD_USER,
                    payload: response,
                });
            }
        });

    const loadSchoolAndCompanyAction = async () => {
        let schoolOrCompany: School | Company | null = null;
        if (grant.hasCompanyAccess) {
            const { response: companyResponse } =
                await CompanyApi.getCollectionMyCompanies<Company>(1, {
                    [`order[name]`]: "asc",
                    pagination: false,
                });

            if (companyResponse && companyResponse?.totalItems > 0) {
                setUserCompanyList(companyResponse.items);
                companyResponse.items.forEach((c) => {
                    if (
                        CompanyApi.toResourceIRI(c.id) ===
                        localStorage.getItem(LSC.APP_SEL_SC)
                    ) {
                        schoolOrCompany = c;
                    }
                });
                if (!schoolOrCompany && !grant.hasSchoolAccess) {
                    schoolOrCompany = companyResponse.items[0];
                }
            }
        }
        if (grant.hasSchoolAccess) {
            const { response: schoolResponse } =
                await SchoolApi.getCollectionMySchools<School>(1, {
                    [`order[name]`]: "asc",
                    pagination: false,
                });

            if (schoolResponse && schoolResponse?.totalItems > 0) {
                setUserSchoolList(schoolResponse.items);
                schoolResponse.items.forEach((c) => {
                    if (
                        SchoolApi.toResourceIRI(c.id) ===
                        localStorage.getItem(LSC.APP_SEL_SC)
                    ) {
                        schoolOrCompany = c;
                    }
                });
                if (!schoolOrCompany) {
                    schoolOrCompany = schoolResponse.items[0];
                }
            }
        }

        if (schoolOrCompany) {
            setActiveSchoolOrCompany(schoolOrCompany);
            localStorage.setItem(LSC.APP_SEL_SC, schoolOrCompany?.["@id"]);
        }
    };

    return {
        isAuthenticated,
        setAuthTokenAction,
        setUserAction,
        loginAction,
        tokenLoginAction,
        logoutAction,
        reloadUserAction,
        loadSchoolAndCompanyAction,
    };
}
