import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Canceler } from "axios";
import { useFormContainerState, useTrans } from "../../../hooks";
import { VStack, useToast } from "@chakra-ui/react";
import {
    AppLoader,
    AppOptionBoxItem,
    AppOptionsBox,
    AppPageHeader,
    AppWidgetCard,
} from "../../../components";
import { CompanyForm as FormComponent } from "./CompanyForm";
import { Company as Entity, CompanyApi as EntityApi } from "../../../apis";
import { cPath } from "../../../config";

export const MyCompanyPage = () => {
    // hook
    const { t } = useTrans();
    const toast = useToast();
    const navigate = useNavigate();
    const formContainerState = useFormContainerState();

    // state & const
    const [loadingList, setLoadingList] = useState(true);
    const [list, setList] = useState<Entity[]>([]);
    const [isDetailPage, setIsDetailPage] = useState(false);
    const cancelTokenSourcesRef = useRef<Canceler[]>([]);

    // handler
    const fetchListData = async () => {
        setLoadingList(true);
        const params = {
            pagination: false,
            "order[name]": "asc",
        };

        await EntityApi.getCollectionMyCompanies<Entity>(1, params, (c) => {
            cancelTokenSourcesRef.current.push(c);
        })
            .then(({ errorMessage, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    setList(response.items);
                }
            })
            .finally(() => setLoadingList(false));
    };

    const actionOptions: AppOptionBoxItem[] = [
        {
            key: "serviceGroup",
            label: t("padm.CompanyPage:text.option.serviceGroup"),
            iconName: "icl-chart-pie",
        },
        {
            key: "detail",
            label: t("cmn:option.detail"),
            iconName: "icl-eye",
        },
        {
            key: "edit",
            label: t("cmn:option.edit"),
            iconName: "icl-pencil",
        },
    ];

    const onActionClick = (key: string, data?: Entity) => {
        if (!data) {
            return;
        }
        switch (key) {
            case "edit":
                setIsDetailPage(false);
                formContainerState.open(data?.id);
                break;
            case "serviceGroup":
                navigate(
                    cPath("ADMIN.SERVICEGROUP_PAGE", {
                        id: data?.id,
                    })
                );
                break;
            case "detail":
                setIsDetailPage(true);
                formContainerState.open(data?.id);
                break;
        }
    };

    // useEffect
    useEffect(() => {
        if (!formContainerState.isOpen) {
            fetchListData();
        }
    }, [formContainerState.isOpen]);

    if (loadingList) {
        return <AppLoader />;
    }

    return (
        <>
            <AppPageHeader
                title={t("padm.MyCompanyPage:text.pageTitle")}
            ></AppPageHeader>
            <VStack p={6} gap={6}>
                {list.map((item) => (
                    <AppWidgetCard
                        key={item.id}
                        data={item}
                        footerElement={
                            <AppOptionsBox
                                options={actionOptions}
                                data={item}
                                onClick={onActionClick}
                            />
                        }
                    />
                ))}
            </VStack>
            {formContainerState.isOpen && (
                <FormComponent
                    formContainerState={formContainerState}
                    isDetailPage={isDetailPage}
                />
            )}
        </>
    );
};
