export const APP_PROTOCOL = process.env.REACT_APP_PROTOCOL;
export const APP_DOMAIN = process.env.REACT_APP_DOMAIN;
export const APP_BASEPATH = `${APP_PROTOCOL}://${APP_DOMAIN}`;
export const PAGE_SIZE = 10;
export const PAGE_SIZES = [10, 30, 50, 100];
export const FORM_CONTAINER = "drawer";

export const BE = {
    User: {
        // ROLE: {
        //     ROLE_SUPER_ADMIN: "ROLE_SUPER_ADMIN",
        //     ROLE_INTERNSHIP_COORDINATOR_TRAINER:
        //         "ROLE_INTERNSHIP_COORDINATOR_TRAINER",
        //     ROLE_INTERNSHIP_REFERENCE_TRAINER:
        //         "ROLE_INTERNSHIP_REFERENCE_TRAINER",
        //     ROLE_PEDAGOGICAL_REFERENCE_TRAINER:
        //         "ROLE_PEDAGOGICAL_REFERENCE_TRAINER",
        //     ROLE_SCHOOL_DIRECTOR: "ROLE_SCHOOL_DIRECTOR",
        //     ROLE_STUDENT: "ROLE_STUDENT",
        //     ROLE_INTERNSHIP_COORDINATOR: "ROLE_INTERNSHIP_COORDINATOR",
        //     ROLE_INTERNSHIP_SUPERVISOR: "ROLE_INTERNSHIP_SUPERVISOR",
        //     ROLE_INTERNSHIP_TUTOR: "ROLE_INTERNSHIP_TUTOR",
        //     ROLE_COMPANY_DIRECTOR: "ROLE_COMPANY_DIRECTOR",
        //     ROLE_SCHOOL_AND_COMPANY: "ROLE_SCHOOL_AND_COMPANY",
        // },
        NATIONALITY: {
            NATIONALITY_FRENCH: "French",
            NATIONALITY_INDIAN: "Indian",
        },
        STUDENT_STATUS: {
            STUDENT_STATUS_PENDING: "PENDING",
            STUDENT_STATUS_CONFIRMED: "CONFIRMED",
        },
        USERTYPE: {
            USERTYPE_INTERNSHIP_COORDINATOR_TRAINER:
                "INTERNSHIP_COORDINATOR_TRAINER",
            USERTYPE_INTERNSHIP_REFERENCE_TRAINER:
                "INTERNSHIP_REFERENCE_TRAINER",
            USERTYPE_PEDAGOGICAL_REFERENCE_TRAINER:
                "PEDAGOGICAL_REFERENCE_TRAINER",
            USERTYPE_SCHOOL_DIRECTOR: "SCHOOL_DIRECTOR",
            USERTYPE_STUDENT: "STUDENT",
            USERTYPE_INTERNSHIP_COORDINATOR: "INTERNSHIP_COORDINATOR",
            USERTYPE_INTERNSHIP_SUPERVISOR: "INTERNSHIP_SUPERVISOR",
            USERTYPE_INTERNSHIP_TUTOR: "INTERNSHIP_TUTOR",
            USERTYPE_COMPANY_DIRECTOR: "COMPANY_DIRECTOR",
            USERTYPE_SCHOOL_AND_COMPANY: "SCHOOL_AND_COMPANY",
            USERTYPE_SUPER_ADMIN: "SUPER_ADMIN",
        },
    },
    Category: {
        TYPE: {
            TYPE_COMPANY: "Company",
            TYPE_SCHOOL: "School",
        },
    },
    Upload: {
        FILETYPE: {
            FILETYPE_USER_AVATAR: "USER_AVATAR",
            FILETYPE_COMPANY_POSTER: "COMPANY_POSTER",
            FILETYPE_SCHOOL_POSTER: "SCHOOL_POSTER",
        },
        FILETYPEINFO: {
            FILETYPEINFO_USER_AVATAR: {
                key: "USER_AVATAR",
                width: "200",
                height: "200",
                ratio: 1 / 1,
                maxSize: null,
                allowType: ["jpg", "jpeg", "png", "gif", "webp"],
                isSecure: false,
                path: "user_avatar",
            },
            FILETYPEINFO_COMPANY_POSTER: {
                key: "COMPANY_POSTER",
                width: "200",
                height: "200",
                ratio: null,
                maxSize: null,
                allowType: ["jpg", "jpeg", "png", "gif", "webp"],
                isSecure: false,
                path: "company_poster",
            },
            FILETYPEINFO_SCHOOL_POSTER: {
                key: "SCHOOL_POSTER",
                width: "200",
                height: "200",
                ratio: null,
                maxSize: null,
                allowType: ["jpg", "jpeg", "png", "gif", "webp"],
                isSecure: false,
                path: "school_poster",
            },
        },
    },
};
