import { BaseEntity } from "./BaseEntity";
import { Category } from "./Category";
import { Department } from "./Department";
import { Region } from "./Region";

export class Company extends BaseEntity {
    public name: string = "";
    public description?: string = "";
    public imageName?: string = "";
    public externalCode?: string = "";
    public siret?: string = "";
    public phone?: string = "";
    public address?: string = "";
    public zipCode?: string = "";
    public city?: string = "";
    public latitude?: string = "";
    public longitude?: string = "";
    public isActive?: boolean = false;
    public category?: string | Category = "";
    public region?: string | Region = "";
    public department?: string | Department = "";

    // Blameable
    public createdBy?: string = "";
    public updatedBy?: string = "";

    constructor(model: Partial<Company> = {}) {
        super(model?.id);
        Object.assign(this, model);
    }
}

export type PCompany = Partial<Company>;
