import { FC, useEffect, useState } from "react";
import { FormContainerStateReturnType, useTrans } from "../../../hooks";
import { useAuthData } from "../../../contexts";
import {
    VStack,
    Input,
    Stack,
    Box,
    Textarea,
    Switch,
    Badge,
    useToast,
    Button,
} from "@chakra-ui/react";
import {
    Company,
    CompanyApi,
    User as Entity,
    UserApi as EntityApi,
    School,
    SchoolApi,
    UploadApi,
} from "../../../apis";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema";
import { AppFormContainer } from "../../../containers";
import {
    AppFormControl,
    AppImageUploader,
    AppMultiSelector,
    AppMultiSelectorOption,
    AppMultiSelectorOptionType,
    AppSelect,
    AppSelectOption,
} from "../../../components";
import { useRecoilValue } from "recoil";
import { ActiveSchoolOrCompanyAtom } from "../../../atoms";
import { APPC, UTIL } from "../../../config";
import dayjs from "dayjs";

export const UserForm: FC<{
    formContainerState: FormContainerStateReturnType;
}> = ({ formContainerState }) => {
    // hook
    const { t } = useTrans();
    const toast = useToast();
    const { grant } = useAuthData();
    const activeSchoolOrCompany = useRecoilValue(ActiveSchoolOrCompanyAtom);

    // state & const
    const isEditMode: boolean = !!formContainerState.entityId;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<Entity>(new Entity());
    const userType: string =
        formContainerState?.entityData?.userType || data?.userType || "";
    const [imageNameFile, setImageNameFile] = useState<File | undefined>(
        undefined
    );
    const [selectedNationality, setSelectedNationality] =
        useState<AppSelectOption | null>(
            data.nationality
                ? {
                      value: data.nationality,
                      label: data.nationality,
                  }
                : {
                      value: APPC.BE.User.NATIONALITY.NATIONALITY_FRENCH,
                      label: APPC.BE.User.NATIONALITY.NATIONALITY_FRENCH,
                  }
        );
    const [selectedStatus, setSelectedStatus] =
        useState<AppSelectOption | null>(
            data.status
                ? {
                      value: data.status,
                      label: t(`con.User:STUDENT_STATUS_${data.status}`),
                  }
                : {
                      value: APPC.BE.User.STUDENT_STATUS.STUDENT_STATUS_PENDING,
                      label: t(`con.User:STUDENT_STATUS_PENDING`),
                  }
        );
    const [selectedCompanies, setSelectedCompanies] = useState<
        AppMultiSelectorOption[]
    >([]);
    const [selectedSchools, setSelectedSchools] = useState<
        AppMultiSelectorOption[]
    >([]);

    const {
        handleSubmit,
        register,
        reset,
        control,
        formState: { errors },
    } = useForm<Entity>({
        resolver: yupResolver(schema(t, isEditMode)),
        mode: "onSubmit",
    });

    // handler
    const onSubmitHandler = async (formData: Entity) => {
        setLoading(true);
        if (imageNameFile) {
            const uploadFormData = new FormData();
            uploadFormData.set(
                "fileType",
                APPC.BE.Upload.FILETYPE.FILETYPE_USER_AVATAR
            );
            uploadFormData.set("file", imageNameFile);

            await UploadApi.upload<{ fileName: string }, FormData>(
                uploadFormData
            ).then(({ response, errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    formData.imageName = response.fileName;
                }
            });
        }
        formData.userType = userType;
        formData.dob = formData.dob ? formData.dob : null;
        if (grant.isSuperAdmin) {
            formData.companies = selectedCompanies?.map((item) => item.value);
            formData.schools = selectedSchools?.map((item) => item.value);
        }
        if (!isEditMode && activeSchoolOrCompany) {
            if (UTIL.isSchoolType(userType)) {
                formData.schools = [activeSchoolOrCompany?.["@id"]];
            }
            if (UTIL.isCompanyType(userType)) {
                formData.companies = [activeSchoolOrCompany?.["@id"]];
            }
        }
        EntityApi.createOrUpdate<Entity, Entity>(
            formContainerState.entityId,
            formData
        )
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    toast({
                        title: t("cmn:message.save.success"),
                        status: "success",
                    });
                    reset();
                    formContainerState.close();
                }
            })
            .finally(() => setLoading(false));
    };

    const loadCompanyOptions = async (
        page: number,
        search: string,
        pageSize: number
    ): Promise<AppMultiSelectorOptionType> => {
        const params = {
            itemsPerPage: pageSize,
            [`order[name]`]: "asc",
            pagination: true,
        };

        if (search) {
            params["name"] = search;
        }

        const { response } = await CompanyApi.getCollection<Company>(
            page,
            params
        );

        if (response !== null) {
            const newOptions =
                response.items?.map((e) => ({
                    value: CompanyApi.toResourceIRI(e.id),
                    label: `${e.id} ${e.name}`,
                })) || [];
            return {
                data: newOptions,
                totalItems: response.totalItems,
            };
        }

        return { data: [], totalItems: 0 };
    };

    const loadSchoolOptions = async (
        page: number,
        search: string,
        pageSize: number
    ): Promise<AppMultiSelectorOptionType> => {
        const params = {
            itemsPerPage: pageSize,
            [`order[name]`]: "asc",
            pagination: true,
        };

        if (search) {
            params["name"] = search;
        }

        const { response } = await SchoolApi.getCollection<School>(
            page,
            params
        );

        if (response !== null) {
            const newOptions =
                response.items?.map((e) => ({
                    value: SchoolApi.toResourceIRI(e.id),
                    label: `${e.id} ${e.name}`,
                })) || [];
            return {
                data: newOptions,
                totalItems: response.totalItems,
            };
        }

        return { data: [], totalItems: 0 };
    };

    // useEffect
    useEffect(() => {
        if (!formContainerState.entityId) {
            reset();
            setData(new Entity());
            setLoading(false);
            return;
        }

        EntityApi.getItem<Entity>(formContainerState.entityId)
            .then(({ errorMessage, isNotFound, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (isNotFound) {
                    toast({
                        title: t("cmn:message.entityNotFound"),
                        status: "error",
                    });
                } else if (response !== null) {
                    reset();
                    if (UTIL.isSchoolCompanyMultiSelect(response?.userType)) {
                        setSelectedSchools(
                            (response.schools as School[]).map((item) => ({
                                value: SchoolApi.toResourceIRI(item.id),
                                label: item.name,
                            }))
                        );
                        setSelectedCompanies(
                            (response.companies as Company[]).map((item) => ({
                                value: CompanyApi.toResourceIRI(item.id),
                                label: item.name,
                            }))
                        );
                    }
                    if (response.nationality) {
                        setSelectedNationality({
                            value: response.nationality,
                            label: response.nationality,
                        });
                    }
                    if (response.status) {
                        setSelectedStatus({
                            value: response.status,
                            label: t(
                                `con.User:STUDENT_STATUS_${response.status}`
                            ),
                        });
                    }
                    setData(response);
                }
            })
            .finally(() => setLoading(false));
    }, [formContainerState.entityId]);

    // render
    return (
        <AppFormContainer
            title={t("padm.UserForm:text.pageTitle")}
            state={formContainerState}
            type={APPC.FORM_CONTAINER}
            size={"xl"}
        >
            <form
                id="app-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(onSubmitHandler)();
                }}
            >
                <VStack gap={5} alignItems={"stretch"}>
                    <AppFormControl
                        label={t("ent.User:avatar.label")}
                        isLoading={loading}
                    >
                        <AppImageUploader
                            fileType={
                                APPC.BE.Upload.FILETYPE.FILETYPE_USER_AVATAR
                            }
                            fileName={data?.imageName}
                            onDone={(file) => setImageNameFile(file)}
                            isAvatar
                        />
                    </AppFormControl>
                    <Badge variant={"secondary"} alignSelf={"flex-start"}>
                        {t(`con.User:USERTYPE_${userType}`)}
                    </Badge>
                    <AppFormControl
                        label={t("ent.User:email.label")}
                        isInvalid={!!errors?.email}
                        message={errors?.email?.message}
                        isLoading={loading}
                    >
                        <Input
                            type="email"
                            placeholder={t("ent.User:email.placeholder")}
                            {...register("email")}
                            defaultValue={data.email}
                            disabled={isEditMode}
                        />
                    </AppFormControl>
                    {!isEditMode && (
                        <Stack
                            gap={5}
                            direction={{
                                base: "column",
                                sm: "column",
                                md: "row",
                            }}
                        >
                            <AppFormControl
                                label={t("ent.User:plainPassword.label")}
                                isInvalid={!!errors?.plainPassword}
                                message={errors?.plainPassword?.message}
                            >
                                <Input
                                    type="password"
                                    placeholder={t(
                                        "ent.User:plainPassword.placeholder"
                                    )}
                                    {...register("plainPassword")}
                                />
                            </AppFormControl>
                            <AppFormControl
                                label={t("ent.User:plainPasswordConfirm.label")}
                                isInvalid={!!errors?.plainPasswordConfirm}
                                message={errors?.plainPasswordConfirm?.message}
                            >
                                <Input
                                    type="password"
                                    placeholder={t(
                                        "ent.User:plainPasswordConfirm.placeholder"
                                    )}
                                    {...register("plainPasswordConfirm")}
                                />
                            </AppFormControl>
                        </Stack>
                    )}
                    <Stack
                        gap={5}
                        direction={{ base: "column", sm: "column", md: "row" }}
                    >
                        <AppFormControl
                            label={t("ent.User:firstName.label")}
                            isInvalid={!!errors?.firstName}
                            message={errors?.firstName?.message}
                            isLoading={loading}
                        >
                            <Input
                                type="text"
                                placeholder={t(
                                    "ent.User:firstName.placeholder"
                                )}
                                {...register("firstName")}
                                defaultValue={data.firstName}
                            />
                        </AppFormControl>
                        <AppFormControl
                            label={t("ent.User:lastName.label")}
                            isInvalid={!!errors?.lastName}
                            message={errors?.lastName?.message}
                            isLoading={loading}
                        >
                            <Input
                                type="text"
                                placeholder={t("ent.User:lastName.placeholder")}
                                {...register("lastName")}
                                defaultValue={data.lastName}
                            />
                        </AppFormControl>
                    </Stack>
                    {UTIL.isSchoolCompanyMultiSelect(userType) && (
                        <Stack
                            gap={5}
                            direction={{
                                base: "column",
                                sm: "column",
                                md: "row",
                            }}
                        >
                            {UTIL.isSchoolType(userType) && (
                                <AppFormControl
                                    label={t("ent.User:schools.label")}
                                    isLoading={loading}
                                >
                                    <AppMultiSelector
                                        onSelect={(data) =>
                                            setSelectedSchools(data)
                                        }
                                        loadOptions={loadSchoolOptions}
                                        selectedOpt={selectedSchools}
                                        subject={t("ent.User:Schools.label")}
                                        triggerElement={
                                            <Button
                                                size={"sm"}
                                                variant={"secondary"}
                                            >
                                                Select School
                                            </Button>
                                        }
                                        isModal={true}
                                        isSearch={true}
                                        selectorRows={8}
                                        maxSelectOptions={
                                            UTIL.isSchoolCompanyMultiSelect(
                                                userType
                                            )
                                                ? undefined
                                                : 1
                                        }
                                    />
                                </AppFormControl>
                            )}
                            {UTIL.isCompanyType(userType) && (
                                <AppFormControl
                                    label={t("ent.User:companies.label")}
                                    isLoading={loading}
                                >
                                    <AppMultiSelector
                                        onSelect={(data) =>
                                            setSelectedCompanies(data)
                                        }
                                        loadOptions={loadCompanyOptions}
                                        selectedOpt={selectedCompanies}
                                        subject={t("ent.User:companies.label")}
                                        triggerElement={
                                            <Button
                                                size={"sm"}
                                                variant={"secondary"}
                                            >
                                                Select Company
                                            </Button>
                                        }
                                        isModal={true}
                                        isSearch={true}
                                        selectorRows={8}
                                        maxSelectOptions={
                                            UTIL.isSchoolCompanyMultiSelect(
                                                userType
                                            )
                                                ? undefined
                                                : 1
                                        }
                                    />
                                </AppFormControl>
                            )}
                        </Stack>
                    )}
                    {userType === APPC.BE.User.USERTYPE.USERTYPE_STUDENT && (
                        <>
                            <Stack
                                gap={5}
                                direction={{
                                    base: "column",
                                    sm: "column",
                                    md: "row",
                                }}
                            >
                                <AppFormControl
                                    label={t("ent.User:dob.label")}
                                    isLoading={loading}
                                >
                                    <Input
                                        type="date"
                                        placeholder={t(
                                            "ent.User:dob.placeholder"
                                        )}
                                        {...register("dob")}
                                        defaultValue={
                                            data.dob
                                                ? dayjs(data?.dob).format(
                                                      "YYYY-MM-DD"
                                                  )
                                                : undefined
                                        }
                                    />
                                </AppFormControl>
                                <AppFormControl
                                    label={t("ent.User:phone.label")}
                                    isLoading={loading}
                                >
                                    <Input
                                        type="text"
                                        placeholder={t(
                                            "ent.User:phone.placeholder"
                                        )}
                                        {...register("phone")}
                                        defaultValue={data.phone}
                                    />
                                </AppFormControl>
                                <AppFormControl
                                    label={t("ent.User:nationality.label")}
                                    isLoading={loading}
                                >
                                    <Controller
                                        control={control}
                                        name="nationality"
                                        defaultValue={
                                            data.nationality ||
                                            APPC.BE.User.NATIONALITY
                                                .NATIONALITY_FRENCH
                                        }
                                        render={({ field }) => (
                                            <AppSelect<AppSelectOption>
                                                name={field.name}
                                                isClearable
                                                isLoading={loading}
                                                options={Object.values(
                                                    APPC.BE.User.NATIONALITY
                                                ).map((nat) => ({
                                                    value: nat,
                                                    label: nat,
                                                }))}
                                                value={selectedNationality}
                                                placeholder={t(
                                                    "ent.User:nationality.placeholder"
                                                )}
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e?.value || null
                                                    );
                                                    setSelectedNationality(
                                                        e as AppSelectOption
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </AppFormControl>
                                <AppFormControl
                                    label={t("ent.User:status.label")}
                                    isLoading={loading}
                                >
                                    <Controller
                                        control={control}
                                        name="status"
                                        defaultValue={
                                            data.status ||
                                            APPC.BE.User.STUDENT_STATUS
                                                .STUDENT_STATUS_PENDING
                                        }
                                        render={({ field }) => (
                                            <AppSelect<AppSelectOption>
                                                name={field.name}
                                                isClearable
                                                isLoading={loading}
                                                options={Object.values(
                                                    APPC.BE.User.STUDENT_STATUS
                                                ).map((s) => ({
                                                    value: s,
                                                    label: t(
                                                        `con.User:STUDENT_STATUS_${s}`
                                                    ),
                                                }))}
                                                value={selectedStatus}
                                                placeholder={t(
                                                    "ent.User:status.placeholder"
                                                )}
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e?.value || null
                                                    );
                                                    setSelectedStatus(
                                                        e as AppSelectOption
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </AppFormControl>
                            </Stack>
                            <Stack
                                w={"full"}
                                gap={5}
                                direction={{
                                    base: "column",
                                    sm: "column",
                                    md: "row",
                                }}
                            >
                                <Box
                                    w={{ base: "full", sm: "full", md: "50%" }}
                                >
                                    <AppFormControl
                                        label={t("ent.User:address.label")}
                                        isLoading={loading}
                                    >
                                        <Textarea
                                            h="131px"
                                            resize={"none"}
                                            placeholder={t(
                                                "ent.User:address.placeholder"
                                            )}
                                            {...register("address")}
                                            defaultValue={data.address}
                                        />
                                    </AppFormControl>
                                </Box>
                                <Box
                                    w={{ base: "full", sm: "full", md: "50%" }}
                                >
                                    <VStack
                                        justifyContent={"space-between"}
                                        gap={5}
                                    >
                                        <AppFormControl
                                            label={t("ent.User:zipCode.label")}
                                            isInvalid={!!errors.zipCode}
                                            message={errors.zipCode?.message}
                                            isLoading={loading}
                                        >
                                            <Input
                                                type="text"
                                                placeholder={t(
                                                    "ent.User:zipCode.placeholder"
                                                )}
                                                {...register("zipCode")}
                                                defaultValue={data.zipCode}
                                            />
                                        </AppFormControl>
                                        <AppFormControl
                                            label={t("ent.User:city.label")}
                                            isLoading={loading}
                                        >
                                            <Input
                                                type="text"
                                                placeholder={t(
                                                    "ent.User:city.placeholder"
                                                )}
                                                {...register("city")}
                                                defaultValue={data.city}
                                            />
                                        </AppFormControl>
                                    </VStack>
                                </Box>
                            </Stack>
                        </>
                    )}
                    <AppFormControl
                        label={t("ent.User:isActive.label")}
                        isInvalid={!!errors.isActive}
                        message={errors.isActive?.message}
                        isLoading={loading}
                    >
                        <Switch
                            {...register("isActive")}
                            defaultChecked={isEditMode ? !!data.isActive : true}
                        />
                    </AppFormControl>
                </VStack>
            </form>
        </AppFormContainer>
    );
};
