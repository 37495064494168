export class BaseEntity {
    id: number;

    createdAt: string;

    updatedAt: string;

    constructor(id?: number, createdAt?: string, updatedAt?: string) {
        this.id = id || 0;
        this.createdAt = createdAt || new Date().toISOString();
        this.updatedAt = updatedAt || new Date().toISOString();
    }

    getId(): number {
        if (this.id === undefined) {
            throw new Error(
                "Before initialization, you're trying to access `id`"
            );
        }
        return this.id;
    }
}
