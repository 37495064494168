import { FC } from "react";
import { useResolveUploadedPath, useTrans } from "../../../hooks";
import { useAuthData } from "../../../contexts";
import { Avatar, Badge, HStack, Text } from "@chakra-ui/react";
import {
    DataType,
    IKaTableProps,
    ITableInstance,
    Table as KaTable,
    SortingMode,
} from "ka-table";
import { Column, PagingOptions } from "ka-table/models";
import { ICellTextProps } from "ka-table/props";
import {
    AppOptionBoxItem,
    AppOptionsBox,
    KaPageSizeSelector,
} from "../../../components";
import { APPC } from "../../../config";

export const UserList: FC<{
    table: ITableInstance;
    paging: PagingOptions;
    list: any[];
    loadingList?: boolean;
    onActionClick?: (key: string, data?: any) => void;
}> = ({
    table,
    paging,
    list,
    loadingList = false,
    onActionClick = () => {},
}) => {
    const { t } = useTrans();
    const { user, grant } = useAuthData();

    const columns: Column[] = [
        {
            key: "firstName",
            title: t("ent.User:name.label"),
            dataType: DataType.String,
        },
        {
            key: "email",
            title: t("ent.User:email.label"),
            dataType: DataType.String,
        },
        {
            key: "userType",
            title: t("ent.User:userType.label"),
            dataType: DataType.String,
            isSortable: false,
        },
        {
            key: "isActive",
            title: t("ent.User:isActive.label"),
            dataType: DataType.Boolean,
        },
        {
            key: "action",
            title: "",
            style: { textAlign: "right" },
            dataType: DataType.Object,
            width: 50,
            isSortable: false,
        },
    ];

    const actionOptions: AppOptionBoxItem[] = [
        {
            key: "edit",
            label: t("cmn:option.edit"),
            iconName: "icl-pencil",
        },
        {
            key: "delete",
            label: t("cmn:option.delete"),
            iconName: "icl-trash",
        },
    ];

    if (grant.isSuperAdmin) {
        actionOptions.unshift({
            key: "login",
            label: t("cmn:option.login"),
            iconName: "icl-key",
        });
    }

    const filterActionOptions = (props: ICellTextProps) => {
        return actionOptions.map((o) => {
            if (o.key === "delete") {
                o.isDisabled =
                    props?.rowData?.userType ===
                        APPC.BE.User.USERTYPE.USERTYPE_SUPER_ADMIN ||
                    props?.rowData?.id === user?.id;
            } else if (o.key === "edit") {
                o.isDisabled =
                    props?.rowData?.userType !==
                        APPC.BE.User.USERTYPE.USERTYPE_SUPER_ADMIN &&
                    props?.rowData?.id === user?.id;
            } else if (o.key === "login") {
                o.isDisabled =
                    props?.rowData?.userType ===
                    APPC.BE.User.USERTYPE.USERTYPE_SUPER_ADMIN;
            }
            return o;
        });
    };

    const kaTableProps: IKaTableProps = {
        table: table,
        columns: columns,
        data: list || [],
        loading: {
            enabled: loadingList,
        },
        paging: paging,
        rowKeyField: "id",
        sortingMode: SortingMode.SingleRemote,
        childComponents: {
            pagingSizes: {
                content: (props) => <KaPageSizeSelector {...props} />,
            },
            cellText: {
                content: (props: ICellTextProps) => {
                    switch (props?.column?.key) {
                        case "firstName":
                            return (
                                <HStack>
                                    <Avatar
                                        size={"sm"}
                                        name={props?.rowData?.fullName}
                                        src={useResolveUploadedPath(
                                            APPC.BE.Upload.FILETYPE
                                                .FILETYPE_USER_AVATAR,
                                            props?.rowData?.imageName
                                        )}
                                        color={
                                            "var(--chakra-colors-primaryS10)"
                                        }
                                        bgColor={
                                            "var(--chakra-colors-primaryT80)"
                                        }
                                    />
                                    <Text
                                        textStyle={"mediumSm"}
                                        color={"black"}
                                    >
                                        {props?.rowData?.fullName}
                                    </Text>
                                </HStack>
                            );
                        case "userType":
                            return (
                                <Badge variant={"secondary"}>
                                    {t(
                                        `con.User:USERTYPE_${props?.rowData?.userType}`
                                    )}
                                </Badge>
                            );
                        case "isActive":
                            return (
                                <Badge
                                    variant={
                                        props?.rowData?.isActive
                                            ? "green"
                                            : "disabled"
                                    }
                                >
                                    {t(
                                        `cmn:label.active.${props?.rowData?.isActive}`
                                    )}
                                </Badge>
                            );
                        case "action":
                            return (
                                <HStack justify={"flex-end"} gap={3}>
                                    <AppOptionsBox
                                        options={filterActionOptions(props)}
                                        data={props?.rowData}
                                        onClick={onActionClick}
                                    />
                                </HStack>
                            );
                    }
                },
            },
        },
    };

    return <KaTable {...kaTableProps} />;
};
