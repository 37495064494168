import { FC, useEffect, useState } from "react";
import { FormContainerStateReturnType, useTrans } from "../../../hooks";
import {
    VStack,
    Input,
    useToast,
    RadioGroup,
    HStack,
    Radio,
} from "@chakra-ui/react";
import { Category as Entity, CategoryApi as EntityApi } from "../../../apis";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema";
import { AppFormContainer } from "../../../containers";
import { AppFormControl } from "../../../components";
import { APPC } from "../../../config";

export const CategoryForm: FC<{
    formContainerState: FormContainerStateReturnType;
}> = ({ formContainerState }) => {
    // hook
    const { t } = useTrans();
    const toast = useToast();

    // state & const
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<Entity>(new Entity());

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors },
    } = useForm<Entity>({
        resolver: yupResolver(schema(t)),
        mode: "onSubmit",
    });

    // handler
    const onSubmitHandler = (formData: Entity) => {
        setLoading(true);
        EntityApi.createOrUpdate<Entity, Entity>(
            formContainerState.entityId,
            formData
        )
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    toast({
                        title: t("cmn:message.save.success"),
                        status: "success",
                    });
                    reset();
                    formContainerState.close();
                }
            })
            .finally(() => setLoading(false));
    };

    // useEffect
    useEffect(() => {
        reset();
        setData(new Entity());
        if (!formContainerState.entityId) {
            return;
        }
        setLoading(true);
        EntityApi.getItem<Entity>(formContainerState.entityId)
            .then(({ errorMessage, isNotFound, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (isNotFound) {
                    toast({
                        title: t("cmn:message.entityNotFound"),
                        status: "error",
                    });
                } else if (response !== null) {
                    reset();
                    setData(response);
                }
            })
            .finally(() => setLoading(false));
    }, [formContainerState.entityId]);

    return (
        <AppFormContainer
            title={t("padm.CategoryForm:text.pageTitle")}
            state={formContainerState}
            type={APPC.FORM_CONTAINER}
        >
            <form
                id="app-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(onSubmitHandler)();
                }}
            >
                <VStack gap={5} alignItems={"stretch"}>
                    <AppFormControl
                        label={t("ent.Category:type.label")}
                        isInvalid={!!errors.type}
                        message={errors.type?.message}
                        isLoading={loading}
                    >
                        <RadioGroup
                            defaultValue={
                                data.type || APPC.BE.Category.TYPE.TYPE_COMPANY
                            }
                            w={"full"}
                        >
                            <HStack alignItems={"start"} w={"full"}>
                                <Radio
                                    {...register("type")}
                                    value={APPC.BE.Category.TYPE.TYPE_COMPANY}
                                >
                                    {APPC.BE.Category.TYPE.TYPE_COMPANY}
                                </Radio>
                                <Radio
                                    {...register("type")}
                                    value={APPC.BE.Category.TYPE.TYPE_SCHOOL}
                                >
                                    {APPC.BE.Category.TYPE.TYPE_SCHOOL}
                                </Radio>
                            </HStack>
                        </RadioGroup>
                    </AppFormControl>
                    <AppFormControl
                        label={t("ent.Category:name.label")}
                        isInvalid={!!errors.name}
                        message={errors.name?.message}
                        isLoading={loading}
                    >
                        <Input
                            type="text"
                            placeholder={t("ent.Category:name.placeholder")}
                            {...register("name")}
                            defaultValue={data.name}
                        />
                    </AppFormControl>
                </VStack>
            </form>
        </AppFormContainer>
    );
};
