import { useTrans, useResolveUploadedPath } from "../hooks";
import { Text, Card, Stack, CardBody, CardFooter, Box } from "@chakra-ui/react";
import { Company, School } from "../apis";
import { APPC } from "../config";
import imagePlaceholder from "../assets/images/imagePlaceholder.svg";

interface AppWidgetCardProps {
    data: Company | School;
    footerElement?: JSX.Element;
}

export const AppWidgetCard = ({ data, footerElement }: AppWidgetCardProps) => {
    const { t } = useTrans();

    const fileType =
        data["@type"] === "Company"
            ? APPC.BE.Upload.FILETYPE.FILETYPE_COMPANY_POSTER
            : APPC.BE.Upload.FILETYPE.FILETYPE_SCHOOL_POSTER;

    return (
        <Card
            direction={{ base: "column", sm: "column", md: "row" }}
            overflow="hidden"
            variant="outline"
            bg={"var(--chakra-colors-primaryT95)"}
            borderRadius={8}
            borderColor={"var(--chakra-colors-primaryT80)"}
            w={"full"}
        >
            <Box
                minW={{ base: "full", sm: "230px" }}
                h={"230px"}
                backgroundImage={
                    data?.imageName
                        ? useResolveUploadedPath(fileType, data?.imageName)
                        : imagePlaceholder
                }
                backgroundColor={"var(--chakra-colors-white)"}
                backgroundSize={"contain"}
                backgroundRepeat={"no-repeat"}
                backgroundPosition={"center"}
                borderRight={"1px solid var(--chakra-colors-primaryT80)"}
            ></Box>
            <Stack w={"full"}>
                <CardBody>
                    <Text textStyle={"heading5"} fontWeight={600}>
                        {data?.name}
                    </Text>
                    <Box pt={6} pb={6}>
                        <Text textStyle={"mediumSm"}>
                            {typeof data?.category === "object" &&
                            data?.category?.name
                                ? data.category.name
                                : ""}
                        </Text>
                    </Box>
                    <Stack
                        gap={8}
                        direction={{ base: "column", sm: "column", md: "row" }}
                    >
                        <Box>
                            <Text textStyle={"regularXs"}>
                                {t("ent.Company:region.label")}
                            </Text>
                            <Text textStyle={"mediumMd"} noOfLines={1}>
                                {typeof data?.region === "object" &&
                                data?.region?.name
                                    ? data.region.name
                                    : ""}
                            </Text>
                        </Box>
                        <Box>
                            <Text textStyle={"regularXs"}>
                                {t("ent.Company:department.label")}
                            </Text>
                            <Text textStyle={"mediumMd"} noOfLines={1}>
                                {typeof data?.department === "object" &&
                                data?.department?.name
                                    ? data.department.name
                                    : ""}
                            </Text>
                        </Box>
                        <Box>
                            <Text textStyle={"regularXs"}>
                                {t("ent.Company:city.label")}
                            </Text>
                            <Text textStyle={"mediumMd"} noOfLines={1}>
                                {data?.city}
                            </Text>
                        </Box>
                    </Stack>
                </CardBody>
                {footerElement && (
                    <CardFooter justifyContent={"flex-end"} pt={0}>
                        {footerElement}
                    </CardFooter>
                )}
            </Stack>
        </Card>
    );
};
