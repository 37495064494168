import Select, { GroupBase } from "react-select";
import AsyncSelect, { AsyncProps } from "react-select/async";

export interface AppSelectOption {
    value: string | number;
    label: string;
}

const defaultStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused
            ? "var(--chakra-colors-primary)"
            : "var(--chakra-colors-greyT65)",
        height: "41px",
        // minWidth: "350px",
        ":hover": {
            borderColor: state.isFocused
                ? "var(--chakra-colors-primary)"
                : "var(--chakra-colors-greyT40)",
        },
    }),
    placeholder: (baseStyles) => ({
        ...baseStyles,
        fontSize: 14,
        color: "var(--chakra-colors-greyT70)",
        paddingLeft: 4,
    }),
    singleValue: (baseStyles) => ({
        ...baseStyles,
        fontSize: 14,
        paddingLeft: 4,
    }),
    menu: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: "var(--chakra-colors-primaryT95)",
    }),
    menuList: (baseStyles) => ({
        ...baseStyles,
        fontSize: 14,
        marginTop: 10,
        marginBottom: 10,
    }),
    indicatorSeparator: (baseStyles) => ({
        ...baseStyles,
        display: "none",
    }),
    dropdownIndicator: (baseStyles) => ({
        ...baseStyles,
        color: "var(--chakra-colors-primary)",
        ":hover": {
            color: "var(--chakra-colors-primary)",
        },
        svg: {
            height: "18px",
            width: "18px",
            strokeWidth: "0.1",
        },
    }),
    option: (baseStyles) => ({
        ...baseStyles,
        paddingLeft: 15,
        ":active": {
            backgroundColor: "var(--chakra-colors-primaryT70)",
        },
    }),
};

export const AppSelect = <
    Option extends AppSelectOption,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
>({
    loadOptions,
    styles,
    ...otherProps
}: AsyncProps<Option, IsMulti, Group>) => {
    // if (!loadOptions) {
    //     loadOptions = (inputValue: string) => {
    //         return new Promise((resolve) => {
    //             resolve(
    //                 (defaultOptions as Option[])?.filter((item) =>
    //                     item.label
    //                         .toLowerCase()
    //                         .includes(inputValue.toLowerCase())
    //                 )
    //             );
    //         });
    //     };
    // }
    if (loadOptions) {
        return (
            <AsyncSelect
                {...otherProps}
                styles={{
                    ...defaultStyles,
                    ...styles,
                }}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary25: "var(--chakra-colors-primaryT85)",
                        primary: "var(--chakra-colors-primary)",
                    },
                })}
            />
        );
    } else {
        return (
            <Select
                {...otherProps}
                styles={{
                    ...defaultStyles,
                    ...styles,
                }}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary25: "var(--chakra-colors-primaryT85)",
                        primary: "var(--chakra-colors-primary)",
                    },
                })}
            />
        );
    }
};
