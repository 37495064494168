import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Canceler } from "axios";
import { useAppTable, useFormContainerState, useTrans } from "../../../hooks";
import { useAuthData } from "../../../contexts";
import {
    Button,
    VStack,
    HStack,
    Divider,
    Center,
    Text,
    Hide,
    useToast,
    useDisclosure,
} from "@chakra-ui/react";
import { AppSearch } from "../../../containers";
import {
    AppAlertDelete,
    AppIcon,
    AppLoader,
    AppPageHeader,
} from "../../../components";
import { ServiceGroupList as ListComponent } from "./ServiceGroupList";
import { ServiceGroupForm as FormComponent } from "./ServiceGroupForm";
import {
    Company,
    CompanyApi,
    ServiceGroup as Entity,
    ServiceGroupApi as EntityApi,
} from "../../../apis";
import { APPC, cPath } from "../../../config";

export const ServiceGroupPage = () => {
    // hook
    const { t } = useTrans();
    const toast = useToast();
    const { id: companyId } = useParams();
    const formContainerState = useFormContainerState();
    const deleteAlertState = useDisclosure();
    const { grant } = useAuthData();

    // state & const
    const [search, setSearch] = useState("");
    const [loadingList, setLoadingList] = useState(true);
    const [list, setList] = useState<Entity[]>([]);
    const [actionSelectId, setActionSelectId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [company, setCompany] = useState<Company | null>(null);

    const cancelTokenSourcesRef = useRef<Canceler[]>([]);

    const {
        table,
        pageIndex,
        pagesCount,
        pageSize,
        sortColumn,
        sortOrder,
        setPagesCount,
    } = useAppTable("name");

    const paging = {
        enabled: true,
        pageSizes: APPC.PAGE_SIZES,
        pageSize,
        pageIndex,
        pagesCount,
    };

    // handler
    const fetchListData = async () => {
        setLoadingList(true);
        const params = {
            itemsPerPage: pageSize,
        };
        params[`order[${sortColumn}]`] = sortOrder;
        params["company.id"] = companyId;
        if (search) {
            params["service_group_service_search"] = search;
        }

        await EntityApi.getCollection<Entity>(pageIndex + 1, params, (c) => {
            cancelTokenSourcesRef.current.push(c);
        })
            .then(({ errorMessage, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    setPagesCount(Math.ceil(response.totalItems / pageSize));
                    setList(response.items);
                }
            })
            .finally(() => setLoadingList(false));
    };

    const actionClickHandler = (key: string, data?: Entity) => {
        if (!data) {
            return;
        }
        switch (key) {
            case "edit":
                formContainerState.open(data?.id);
                break;
            case "delete":
                setActionSelectId(data?.id);
                deleteAlertState.onOpen();
                break;
        }
    };

    const deleteHandler = async () => {
        if (actionSelectId < 1) {
            return;
        }
        setLoadingList(true);
        await EntityApi.deleteItem(actionSelectId)
            .then(({ errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else {
                    fetchListData();
                }
            })
            .finally(() => setLoadingList(false));
    };

    // useEffect
    useEffect(() => {
        if (!formContainerState.isOpen) {
            fetchListData();
        }
    }, [
        pageIndex,
        pageSize,
        sortColumn,
        sortOrder,
        search,
        formContainerState.isOpen,
    ]);

    useEffect(() => {
        setLoading(true);
        if (companyId) {
            CompanyApi.getItem<Company>(companyId)
                .then(({ errorMessage, isNotFound, response }) => {
                    if (errorMessage) {
                        toast({
                            title: errorMessage,
                            status: "error",
                        });
                    } else if (isNotFound) {
                        toast({
                            title: t("cmn:message.entityNotFound"),
                            status: "error",
                        });
                    } else if (response !== null) {
                        setCompany(response);
                    }
                })
                .finally(() => setLoading(false));
        }
    }, [companyId]);

    if (loading) {
        return <AppLoader />;
    }

    return (
        <>
            <AppPageHeader
                title={t("padm.ServiceGroupPage:text.pageTitle")}
                breadcrumb={
                    company?.name
                        ? [
                              {
                                  label: company?.name,
                                  url: grant.isSuperAdmin
                                      ? cPath("ADMIN.COMPANY_PAGE")
                                      : cPath("ADMIN.MY_COMPANY_PAGE"),
                              },
                          ]
                        : []
                }
            >
                <HStack gap={4} py={{ base: 3.5, sm: 4, md: "1.125rem" }}>
                    <AppSearch onSearch={(value) => setSearch(value)} />
                    <Center height="2.1875rem">
                        <Divider
                            orientation="vertical"
                            borderColor={"var(--chakra-colors-greyT80)"}
                        />
                    </Center>
                    <Button
                        variant={"primary"}
                        onClick={() => formContainerState.open(0)}
                    >
                        <AppIcon name="ics-plus-circle" w="1rem" />
                        <Hide below="md">
                            <Text ml={2}>
                                {t("padm.ServiceGroupPage:button.create")}
                            </Text>
                        </Hide>
                    </Button>
                </HStack>
            </AppPageHeader>
            <VStack p={6}>
                <ListComponent
                    table={table}
                    paging={paging}
                    list={list}
                    loadingList={loadingList}
                    onActionClick={actionClickHandler}
                />
                {deleteAlertState.isOpen && (
                    <AppAlertDelete
                        isOpenDelete={deleteAlertState.isOpen}
                        deleteHandler={() => deleteHandler()}
                        onCloseDelete={deleteAlertState.onClose}
                    />
                )}
            </VStack>
            {formContainerState.isOpen && (
                <FormComponent
                    formContainerState={formContainerState}
                    companyId={companyId || ""}
                />
            )}
        </>
    );
};
