import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";

const baseStyle = defineStyle({
    fontFamily: "Inter Medium, sans-serif",
    fontWeight: 500,
    borderRadius: "0.25rem",
});

const sizes = {
    sm: defineStyle({
        h: "1.15rem",
        px: 1,
        py: 0.75,
        fontSize: "0.625rem",
        lineHeight: "1.075rem",
        textTransform: "none",
    }),
    md: defineStyle({
        h: "1.5625rem",
        px: 1.5,
        py: 1,
        fontSize: "0.75rem",
        lineHeight: "1.0625rem",
        textTransform: "none",
    }),
    lg: defineStyle({
        h: "2.0625rem",
        px: 2,
        py: 1.5,
        fontSize: "0.875rem",
        lineHeight: "1.3125rem",
        textTransform: "none",
    }),
};

const variantDisabled = defineStyle({
    bg: "var(--chakra-colors-greyT95)",
    color: "var(--chakra-colors-greyT75)",
});

const variantGreen = defineStyle({
    bg: "var(--chakra-colors-successT90)",
    color: "var(--chakra-colors-success)",
});

const variantRed = defineStyle({
    bg: "var(--chakra-colors-destructiveT90)",
    color: "var(--chakra-colors-destructive)",
});

const variantPrimary = defineStyle({
    bg: "var(--chakra-colors-primary)",
    color: "var(--chakra-colors-white)",
});

const variantSecondary = defineStyle({
    bg: "var(--chakra-colors-primaryT90)",
    color: "var(--chakra-colors-primary)",
});

const variants = {
    disabled: variantDisabled,
    green: variantGreen,
    red: variantRed,
    primary: variantPrimary,
    secondary: variantSecondary,
};

export const Badge = defineStyleConfig({
    baseStyle,
    variants,
    sizes,
    defaultProps: {
        size: "md",
        variant: "green",
    },
});
