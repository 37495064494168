import { APPC } from "../config";
import { API_HOST } from "../services/ApiPlatform/config";

export function useResolveUploadedPath(
    fileType: string,
    fileName: string | null = null,
    file?: File | undefined
): string {
    if (file) {
        return URL.createObjectURL(file);
    }
    const fileTypeInfo =
        APPC.BE.Upload.FILETYPEINFO[`FILETYPEINFO_${fileType}`];
    const path = fileTypeInfo?.path;

    let basePath = `${API_HOST}/uploads`;

    if (path) {
        basePath = `${basePath}/${path}`;
    }

    if (fileName) {
        return `${basePath}/${fileName}`;
    }

    return "";
}
