import { useState } from "react";
import { useAuthAction, useFormContainerState, useTrans } from "../../../hooks";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema";
import { schemaChangeEmail } from "./schemaChangeEmail";
import { useAuthData } from "../../../contexts";
import {
    AppAlert,
    AppFormControl,
    AppIcon,
    AppImageUploader,
    AppPageHeader,
    AppSelect,
    AppSelectOption,
} from "../../../components";
import {
    User as FormEntity,
    UserApi as FormEntityApi,
    UploadApi,
    UserApi,
} from "../../../apis";
import {
    Badge,
    Box,
    Button,
    Input,
    Stack,
    Text,
    Textarea,
    VStack,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import { APPC, LSC } from "../../../config";
import {
    ChangeEmailPayload,
    ChangeEmailResponse,
    ForgotPasswordPayload,
    ForgotPasswordResponse,
} from "../../../apis/entities/dtos";
import { AppFormContainer } from "../../../containers";
import dayjs from "dayjs";

export const ProfilePage = () => {
    const { t } = useTrans();
    const toast = useToast();
    const { user } = useAuthData();
    const { reloadUserAction, logoutAction } = useAuthAction();
    const formContainerState = useFormContainerState();
    const {
        isOpen: isOpenChangePassword,
        onOpen: onOpenChangePassword,
        onClose: onCloseChangePassword,
    } = useDisclosure();

    // state & const
    const [loading, setLoading] = useState(false);
    const [changeEmailData] = useState<ChangeEmailPayload>({ newEmail: "" });
    const [imageNameFile, setImageNameFile] = useState<File | undefined>(
        undefined
    );
    const [selectedNationality, setSelectedNationality] =
        useState<AppSelectOption | null>(
            user?.nationality
                ? {
                      value: user?.nationality,
                      label: user?.nationality,
                  }
                : {
                      value: APPC.BE.User.NATIONALITY.NATIONALITY_FRENCH,
                      label: APPC.BE.User.NATIONALITY.NATIONALITY_FRENCH,
                  }
        );
    const [locale] = useState(
        localStorage.getItem(LSC.APP_USER_LOCALE) || "en"
    );

    // form
    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
        control,
    } = useForm<FormEntity>({
        resolver: yupResolver(schema(t)),
        mode: "onSubmit",
    });

    const onSubmitHandler = async (formData: FormEntity) => {
        if (user) {
            setLoading(true);
            if (imageNameFile) {
                const uploadFormData = new FormData();
                uploadFormData.set(
                    "fileType",
                    APPC.BE.Upload.FILETYPE.FILETYPE_USER_AVATAR
                );
                uploadFormData.set("file", imageNameFile);

                await UploadApi.upload<{ fileName: string }, FormData>(
                    uploadFormData
                ).then(({ response, errorMessage }) => {
                    if (errorMessage) {
                        toast({
                            title: errorMessage,
                            status: "error",
                        });
                    } else if (response !== null) {
                        formData.imageName = response.fileName;
                    }
                });
            }
            if (!formData.dob) {
                formData.dob = null;
            }
            FormEntityApi.patchItemProfile<FormEntity, FormEntity>(
                user.id,
                formData
            )
                .then(({ response, errorMessage }) => {
                    if (errorMessage) {
                        toast({
                            title: errorMessage,
                            status: "error",
                        });
                    } else if (response !== null) {
                        reloadUserAction();
                        toast({
                            title: t("cmn:message.save.success"),
                            status: "success",
                        });
                        reset();
                        if (
                            locale !== localStorage.getItem(LSC.APP_USER_LOCALE)
                        ) {
                            localStorage.setItem(LSC.APP_USER_LOCALE, locale);
                        }
                        document.location.reload();
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const {
        handleSubmit: changeEmailHandleSubmit,
        register: changeEmailRegister,
        formState: { errors: changeEmailErrors },
        reset: changeEmailReset,
    } = useForm<ChangeEmailPayload>({
        resolver: yupResolver(schemaChangeEmail(t)),
        mode: "onBlur",
    });

    const onSubmitChangeEmailHandler = (formData: ChangeEmailPayload) => {
        if (user) {
            setLoading(true);
            FormEntityApi.patchItemChangeEmail<
                ChangeEmailResponse,
                ChangeEmailPayload
            >(user.id, formData)
                .then(({ response, errorMessage }) => {
                    if (errorMessage) {
                        toast({
                            title: errorMessage,
                            status: "error",
                        });
                    } else if (response !== null) {
                        toast({
                            title: t(
                                "padm.ProfilePage:toast.success.changeEmail"
                            ),
                            status: "success",
                        });
                        changeEmailReset();
                        formContainerState.close();
                        logoutAction();
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const changePasswordHandler = async () => {
        setLoading(true);
        UserApi.postUserChangePasswordRequest<
            ForgotPasswordResponse,
            ForgotPasswordPayload
        >({ email: `${user?.email}` })
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    toast({
                        title: t(
                            "padm.ProfilePage:toast.success.passwordReset"
                        ),
                        status: "success",
                    });
                    reset();
                    logoutAction();
                }
            })
            .finally(() => setLoading(false));
    };

    const renderUserForm = () => {
        return (
            <form
                id="app-form-profile"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(onSubmitHandler)();
                }}
            >
                <VStack gap={6} maxW={"680px"}>
                    <AppFormControl
                        label={t("ent.User:avatar.label")}
                        isLoading={loading}
                    >
                        <AppImageUploader
                            fileType={
                                APPC.BE.Upload.FILETYPE.FILETYPE_USER_AVATAR
                            }
                            fileName={user?.imageName}
                            onDone={(file) => setImageNameFile(file)}
                            isAvatar
                        />
                    </AppFormControl>
                    <Badge variant={"secondary"} alignSelf={"flex-start"}>
                        {t(`con.User:USERTYPE_${user?.userType}`)}
                    </Badge>
                    <AppFormControl label={t("ent.User:email.label")}>
                        <Input
                            type="email"
                            placeholder={t("ent.User:email.placeholder")}
                            isDisabled={true}
                            defaultValue={user?.email}
                        />
                    </AppFormControl>
                    <Stack
                        w={"full"}
                        gap={5}
                        direction={{ base: "column", sm: "column", md: "row" }}
                    >
                        <Button
                            w="50%"
                            variant={"destructive"}
                            onClick={() => formContainerState.open(0)}
                        >
                            <AppIcon name="icl-mail" w="1rem" />
                            <Text ml="2">
                                {t("padm.ProfilePage:button.changeEmail")}
                            </Text>
                        </Button>
                        <Button
                            w="50%"
                            variant={"destructive"}
                            onClick={onOpenChangePassword}
                        >
                            <AppIcon name="icl-key-alt" />
                            <Text ml="2">
                                {t("padm.ProfilePage:button.changePassword")}
                            </Text>
                        </Button>
                    </Stack>
                    <Stack
                        w={"full"}
                        gap={5}
                        direction={{ base: "column", sm: "column", md: "row" }}
                    >
                        <AppFormControl
                            label={t("ent.User:firstName.label")}
                            isInvalid={!!errors?.firstName}
                            message={errors?.firstName?.message}
                            isLoading={loading}
                        >
                            <Input
                                type="text"
                                placeholder={t(
                                    "ent.User:firstName.placeholder"
                                )}
                                {...register("firstName")}
                                defaultValue={user?.firstName}
                            />
                        </AppFormControl>
                        <AppFormControl
                            label={t("ent.User:lastName.label")}
                            isInvalid={!!errors?.lastName}
                            message={errors?.lastName?.message}
                            isLoading={loading}
                        >
                            <Input
                                type="text"
                                placeholder={t("ent.User:lastName.placeholder")}
                                {...register("lastName")}
                                defaultValue={user?.lastName}
                            />
                        </AppFormControl>
                    </Stack>
                    {user?.userType ===
                        APPC.BE.User.USERTYPE.USERTYPE_STUDENT && (
                        <>
                            <Stack
                                w={"full"}
                                gap={5}
                                direction={{
                                    base: "column",
                                    sm: "column",
                                    md: "row",
                                }}
                            >
                                <AppFormControl
                                    label={t("ent.User:dob.label")}
                                    isLoading={loading}
                                >
                                    <Input
                                        type="date"
                                        placeholder={t(
                                            "ent.User:dob.placeholder"
                                        )}
                                        {...register("dob")}
                                        defaultValue={
                                            user?.dob
                                                ? dayjs(user?.dob).format(
                                                      "YYYY-MM-DD"
                                                  )
                                                : ""
                                        }
                                    />
                                </AppFormControl>
                                <AppFormControl
                                    label={t("ent.User:phone.label")}
                                    isLoading={loading}
                                >
                                    <Input
                                        type="text"
                                        placeholder={t(
                                            "ent.User:phone.placeholder"
                                        )}
                                        {...register("phone")}
                                        defaultValue={user?.phone}
                                    />
                                </AppFormControl>
                                <AppFormControl
                                    label={t("ent.User:nationality.label")}
                                    isLoading={loading}
                                >
                                    <Controller
                                        control={control}
                                        name="nationality"
                                        defaultValue={
                                            user?.nationality ||
                                            APPC.BE.User.NATIONALITY
                                                .NATIONALITY_FRENCH
                                        }
                                        render={({ field }) => (
                                            <AppSelect<AppSelectOption>
                                                {...field}
                                                {...register("nationality")}
                                                isClearable
                                                isLoading={loading}
                                                options={Object.values(
                                                    APPC.BE.User.NATIONALITY
                                                ).map((nat) => ({
                                                    value: nat,
                                                    label: nat,
                                                }))}
                                                value={selectedNationality}
                                                placeholder={t(
                                                    "ent.User:nationality.placeholder"
                                                )}
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e?.value || null
                                                    );
                                                    setSelectedNationality(
                                                        e as AppSelectOption
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </AppFormControl>
                            </Stack>
                            <Stack
                                w={"full"}
                                gap={5}
                                direction={{
                                    base: "column",
                                    sm: "column",
                                    md: "row",
                                }}
                            >
                                <Box
                                    w={{ base: "full", sm: "full", md: "50%" }}
                                >
                                    <AppFormControl
                                        label={t("ent.User:address.label")}
                                        isLoading={loading}
                                    >
                                        <Textarea
                                            h="131px"
                                            resize={"none"}
                                            placeholder={t(
                                                "ent.User:address.placeholder"
                                            )}
                                            {...register("address")}
                                            defaultValue={user?.address}
                                        />
                                    </AppFormControl>
                                </Box>
                                <Box
                                    w={{ base: "full", sm: "full", md: "50%" }}
                                >
                                    <VStack
                                        justifyContent={"space-between"}
                                        gap={5}
                                    >
                                        <AppFormControl
                                            label={t("ent.User:zipCode.label")}
                                            isLoading={loading}
                                        >
                                            <Input
                                                type="text"
                                                placeholder={t(
                                                    "ent.User:zipCode.placeholder"
                                                )}
                                                {...register("zipCode")}
                                                defaultValue={user?.zipCode}
                                            />
                                        </AppFormControl>
                                        <AppFormControl
                                            label={t("ent.User:city.label")}
                                            isLoading={loading}
                                        >
                                            <Input
                                                type="text"
                                                placeholder={t(
                                                    "ent.User:city.placeholder"
                                                )}
                                                {...register("city")}
                                                defaultValue={user?.city}
                                            />
                                        </AppFormControl>
                                    </VStack>
                                </Box>
                            </Stack>
                        </>
                    )}
                    <Box alignSelf={"end"}>
                        <Button type="submit" form="app-form-profile">
                            {t("cmn:button.save")}
                        </Button>
                    </Box>
                </VStack>
            </form>
        );
    };

    const renderChangeEmailModal = () => {
        return (
            <AppFormContainer
                title={t("padm.ProfilePage:modal.changeEmail.title")}
                state={formContainerState}
                type={"modal"}
                size={"lg"}
            >
                <form
                    id="app-form"
                    onSubmit={(e) => {
                        e.preventDefault();
                        changeEmailHandleSubmit(onSubmitChangeEmailHandler)();
                    }}
                >
                    <VStack gap={5} py={6}>
                        <AppIcon
                            name="icl-mail"
                            w="2.5rem"
                            color={"var(--chakra-colors-destructive)"}
                        />
                        <AppFormControl label={t("ent.User:email.label")}>
                            <>{`${user?.email}`}</>
                        </AppFormControl>
                        <AppFormControl
                            label={t("ent.User:newEmail.label")}
                            isInvalid={!!changeEmailErrors?.newEmail}
                            message={changeEmailErrors?.newEmail?.message}
                            isLoading={loading}
                        >
                            <Input
                                type="text"
                                placeholder={t("ent.User:newEmail.placeholder")}
                                {...changeEmailRegister("newEmail")}
                                defaultValue={changeEmailData.newEmail}
                            />
                        </AppFormControl>
                        <Text textStyle={"regularSm"}>
                            {t("padm.ProfilePage:alert.changeEmail.detail")}
                        </Text>
                    </VStack>
                </form>
            </AppFormContainer>
        );
    };

    const renderChangePasswordAlert = () => {
        return (
            <AppAlert
                title={t("padm.ProfilePage:alert.changePassword.title")}
                isOpen={isOpenChangePassword}
                onSuccess={changePasswordHandler}
                onCancel={onCloseChangePassword}
                successLable={t(
                    "padm.ProfilePage:alert.changePassword.button.success"
                )}
                cancelLable={t(
                    "padm.ProfilePage:alert.changePassword.button.cancel"
                )}
                size={"lg"}
            >
                <VStack py={6}>
                    <AppIcon
                        name="icl-key-alt"
                        w="2.5rem"
                        color={"var(--chakra-colors-destructive)"}
                    />
                    <Text textStyle={"boldMd"}>
                        {t("padm.ProfilePage:alert.changePassword.message")}
                    </Text>
                    <Text textStyle={"regularSm"}>
                        {t("padm.ProfilePage:alert.changePassword.detail")}
                    </Text>
                </VStack>
            </AppAlert>
        );
    };

    return (
        <>
            <AppPageHeader
                title={t("padm.ProfilePage:text.profile")}
            ></AppPageHeader>
            <Box p={6}>
                {renderUserForm()}
                {renderChangeEmailModal()}
                {isOpenChangePassword && renderChangePasswordAlert()}
            </Box>
        </>
    );
};
