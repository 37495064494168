import { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
    CategoryPage,
    DashboardPage,
    ForgotPasswordPage,
    LoginPage,
    MySchoolPage,
    ProfilePage,
    RegionPage,
    SchoolPage,
    ServiceGroupPage,
    SetPasswordPage,
    TokenLoginPage,
    UserPage,
} from "../pages";
import { useAuthAction } from "../hooks";
import { cPath } from "../config";
import { AppLoader } from "../components";
import { CompanyPage, MyCompanyPage } from "../pages/admin/Company";

const AdminLayout = lazy(() => import("../layout/AdminLayout"));
// const HomeLayout = lazy(() => import("../layout/HomeLayout"));
const LoginLayout = lazy(() => import("../layout/LoginLayout"));

const Logout = () => {
    const { logoutAction } = useAuthAction();
    logoutAction();
    return <></>;
};

const RootRoutes = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
    return (
        <Routes>
            {isAuthenticated ? (
                <>
                    <Route
                        path="*"
                        element={
                            <Navigate
                                to={cPath("ADMIN.DASHBOARD_PAGE")}
                                replace
                            />
                        }
                    />
                    <Route
                        path={cPath("ADMIN")}
                        element={
                            <Suspense fallback={<AppLoader />}>
                                <AdminLayout />
                            </Suspense>
                        }
                    >
                        <Route
                            path={cPath("ADMIN.DASHBOARD_PAGE", {}, true)}
                            element={<DashboardPage />}
                        />
                        <Route
                            path={cPath("ADMIN.PROFILE_PAGE", {}, true)}
                            element={<ProfilePage />}
                        />
                        <Route
                            path={cPath("ADMIN.USER_PAGE", {}, true)}
                            element={<UserPage />}
                        />
                        <Route
                            path={cPath(
                                "ADMIN.USER_PAGE_EDIT",
                                { id: ":id" },
                                true
                            )}
                            element={<UserPage />}
                        />
                        <Route
                            path={cPath("ADMIN.MY_COMPANY_PAGE", {}, true)}
                            element={<MyCompanyPage />}
                        />
                        <Route
                            path={cPath("ADMIN.COMPANY_PAGE", {}, true)}
                            element={<CompanyPage />}
                        />
                        <Route
                            path={cPath("ADMIN.MY_SCHOOL_PAGE", {}, true)}
                            element={<MySchoolPage />}
                        />
                        <Route
                            path={cPath("ADMIN.SCHOOL_PAGE", {}, true)}
                            element={<SchoolPage />}
                        />
                        <Route
                            path={cPath("ADMIN.CATEGORY_PAGE", {}, true)}
                            element={<CategoryPage />}
                        />
                        <Route
                            path={cPath("ADMIN.SERVICEGROUP_PAGE", {}, true)}
                            element={<ServiceGroupPage />}
                        />
                        <Route
                            path={cPath("ADMIN.REGION_PAGE", {}, true)}
                            element={<RegionPage />}
                        />
                        <Route
                            path={cPath("ADMIN.LOGOUT", {}, true)}
                            element={<Logout />}
                        />
                    </Route>
                </>
            ) : (
                <>
                    <Route
                        path="*"
                        element={<Navigate to={cPath("PUBLIC")} replace />}
                    />
                    <Route
                        path={cPath("PUBLIC")}
                        element={
                            <Suspense fallback={<AppLoader />}>
                                <LoginLayout />
                            </Suspense>
                        }
                    >
                        <Route
                            path={cPath(
                                "PUBLIC.SECURITY.TOKEN_LOGIN_PAGE",
                                { token: ":token" },
                                true
                            )}
                            element={<TokenLoginPage />}
                        />
                        <Route
                            path={cPath("PUBLIC.SECURITY.LOGIN_PAGE", {}, true)}
                            element={<LoginPage />}
                        />
                        <Route
                            path={cPath(
                                "PUBLIC.SECURITY.FORGOT_PASSWORD_PAGE",
                                {},
                                true
                            )}
                            element={<ForgotPasswordPage />}
                        />
                        <Route
                            path={cPath(
                                "PUBLIC.SECURITY.SET_PASSWORD_PAGE",
                                {},
                                true
                            )}
                            element={<SetPasswordPage />}
                        />
                    </Route>
                </>
            )}
        </Routes>
    );
};

export default RootRoutes;
