import { EntityApi } from "../../services/ApiPlatform";

export abstract class DepartmentApi extends EntityApi {
    protected static GET_COLLECTION = "/api/departments";

    protected static POST_COLLECTION = "/api/departments";

    protected static GET_ITEM = "/api/departments/{id}";

    protected static PUT_ITEM = "/api/departments/{id}";

    protected static PATCH_ITEM = "/api/departments/{id}";

    protected static DELETE_ITEM = "/api/departments/{id}";
}
