import { useTrans } from "./useTrans";
import { useAuthData } from "../contexts";
import { cPath } from "../config";
import { NavItem } from "../containers/AppNav/AppNav";

export const useNavItems = (type: string = "main"): NavItem[] => {
    const { t } = useTrans();
    const { grant } = useAuthData();

    const userNavItems: NavItem[] = [
        {
            label: t("nav:profile"),
            url: cPath("ADMIN.PROFILE_PAGE"),
            iconName: "icl-address-card",
        },
        {
            label: t("nav:logout"),
            url: cPath("ADMIN.LOGOUT"),
            iconName: "icl-account-logout",
        },
    ];

    const mainNavItems: NavItem[] = [
        {
            label: t("nav:dashboard"),
            url: cPath("ADMIN.DASHBOARD_PAGE"),
            iconName: "icl-view-quilt",
            divider: true,
        },
    ];

    if (!grant.isStudent) {
        mainNavItems.push({
            label: t("nav:administration"),
        });
    }

    if (grant.isInternshipCoordinatorTrainer || grant.isSchoolAndCompany) {
        mainNavItems.push({
            label: t("nav:mySchool"),
            url: cPath("ADMIN.MY_SCHOOL_PAGE"),
            iconName: "icl-school",
        });
    }
    if (grant.isInternshipCoordinator || grant.isSchoolAndCompany) {
        mainNavItems.push({
            label: t("nav:myCompany"),
            url: cPath("ADMIN.MY_COMPANY_PAGE"),
            iconName: "icl-building-business",
        });
    }
    if (
        grant.isInternshipCoordinatorTrainer ||
        grant.isInternshipCoordinator ||
        grant.isSchoolAndCompany
    ) {
        mainNavItems.push({
            label: t("nav:user"),
            url: cPath("ADMIN.USER_PAGE"),
            iconName: "icl-id-badge",
        });
    }

    if (grant.isSuperAdmin) {
        mainNavItems.push(
            {
                label: t("nav:school"),
                url: cPath("ADMIN.SCHOOL_PAGE"),
                iconName: "icl-school",
            },
            {
                label: t("nav:company"),
                url: cPath("ADMIN.COMPANY_PAGE"),
                iconName: "icl-building-business",
            },
            {
                label: t("nav:user"),
                url: cPath("ADMIN.USER_PAGE"),
                iconName: "icl-id-badge",
            },
            {
                label: t("nav:category"),
                url: cPath("ADMIN.CATEGORY_PAGE"),
                iconName: "icl-widgets",
            },
            {
                label: t("nav:regionAndDepartment"),
                url: cPath("ADMIN.REGION_PAGE"),
                iconName: "icl-fork",
            }
        );
    }

    return type === "user" ? userNavItems : mainNavItems;
};
