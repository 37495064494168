import { FC } from "react";
import { NavLink } from "react-router-dom";
import { Box, HStack, Text } from "@chakra-ui/react";

type AppPageHeaderProps = {
    title: string;
    breadcrumb?: { label: string; url: string }[];
    children?: JSX.Element;
};

export const AppPageHeader: FC<AppPageHeaderProps> = ({
    title,
    breadcrumb,
    children,
}) => {
    return (
        <HStack
            minH={"4.3125rem"}
            px={6}
            borderBottom={"1px solid"}
            borderColor={"var(--chakra-colors-primaryT80)"}
            justifyContent={"flex-end"}
            flexWrap={"wrap"}
            gap={0}
        >
            <Box flexGrow={1} mr={6} py={{ base: 3.5, sm: 4, md: "1.125rem" }}>
                {breadcrumb &&
                    breadcrumb.map((item, index) => (
                        <Text
                            textStyle={"heading4"}
                            key={index}
                            display={"inline"}
                        >
                            <NavLink to={item.url}>{item.label}</NavLink>{" "}
                            /&nbsp;
                        </Text>
                    ))}
                <Text textStyle={"heading4"} display={"inline"}>
                    {title}
                </Text>
            </Box>
            {children}
        </HStack>
    );
};
