import { FC } from "react";
import { useResolveUploadedPath, useTrans } from "../../../hooks";
import { School } from "../../../apis";
import { Box, VStack, Stack, Badge, HStack } from "@chakra-ui/react";
import { AppRenderDetailItem } from "../../../components";
import { APPC } from "../../../config";

export const SchoolDetailView: FC<{
    data: School;
}> = ({ data }) => {
    const { t } = useTrans();

    return (
        <>
            <VStack gap={8} alignItems={"stretch"}>
                <Stack
                    w={"full"}
                    gap={8}
                    direction={{ base: "column", sm: "column", md: "row" }}
                >
                    <Box w={{ base: "full", sm: "full", md: "50%" }}>
                        <VStack justifyContent={"space-between"} gap={8}>
                            <AppRenderDetailItem
                                label={t("ent.School:name.label")}
                                value={data.name}
                            />
                            <AppRenderDetailItem
                                label={t("ent.School:category.label")}
                                value={
                                    typeof data?.category === "object" &&
                                    data?.category?.name
                                        ? data.category.name
                                        : ""
                                }
                            />
                            <AppRenderDetailItem
                                label={t("ent.School:region.label")}
                                value={
                                    typeof data?.region === "object" &&
                                    data?.region?.name
                                        ? data.region.name
                                        : ""
                                }
                            />
                            <AppRenderDetailItem
                                label={t("ent.School:department.label")}
                                value={
                                    typeof data?.department === "object" &&
                                    data?.department?.name
                                        ? data.department.name
                                        : ""
                                }
                            />
                        </VStack>
                    </Box>
                    <Box w={{ base: "full", sm: "full", md: "50%" }}>
                        <AppRenderDetailItem
                            renderType="IMAGE"
                            imageUrl={useResolveUploadedPath(
                                APPC.BE.Upload.FILETYPE.FILETYPE_SCHOOL_POSTER,
                                data?.imageName,
                                undefined
                            )}
                        />
                    </Box>
                </Stack>
                <AppRenderDetailItem
                    label={t("ent.School:description.label")}
                    value={data?.description}
                    noOfLines={3}
                />
                <Stack
                    gap={8}
                    direction={{ base: "column", sm: "column", md: "row" }}
                >
                    <AppRenderDetailItem
                        label={t("ent.School:externalCode.label")}
                        value={data.externalCode}
                    />
                    <AppRenderDetailItem
                        label={t("ent.School:siret.label")}
                        value={data.siret}
                    />
                    <AppRenderDetailItem
                        label={t("ent.School:phone.label")}
                        value={data.phone}
                    />
                </Stack>
                <Stack
                    w={"full"}
                    gap={8}
                    direction={{ base: "column", sm: "column", md: "row" }}
                >
                    <Box w={{ base: "full", sm: "full", md: "50%" }}>
                        <AppRenderDetailItem
                            label={t("ent.School:address.label")}
                            value={data?.address}
                            noOfLines={5}
                        />
                    </Box>
                    <Box w={{ base: "full", sm: "full", md: "50%" }}>
                        <VStack justifyContent={"space-between"} gap={8}>
                            <AppRenderDetailItem
                                label={t("ent.School:zipCode.label")}
                                value={data.zipCode}
                            />
                            <AppRenderDetailItem
                                label={t("ent.School:city.label")}
                                value={data.city}
                            />
                        </VStack>
                    </Box>
                </Stack>
                <Stack
                    gap={8}
                    direction={{ base: "column", sm: "column", md: "row" }}
                >
                    <AppRenderDetailItem
                        label={t("ent.School:latitude.label")}
                        value={data.latitude}
                    />
                    <AppRenderDetailItem
                        label={t("ent.School:longitude.label")}
                        value={data.longitude}
                    />
                </Stack>
                <AppRenderDetailItem label={t("ent.School:isActive.label")}>
                    <HStack>
                        <Badge variant={data?.isActive ? "green" : "red"}>
                            {t(
                                `cmn:label.active.${data?.isActive ? data?.isActive : "null"}`
                            )}
                        </Badge>
                    </HStack>
                </AppRenderDetailItem>
            </VStack>
        </>
    );
};
