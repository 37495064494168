import * as yup from "yup";
import { UserApi } from "../../../apis";

export const schema = (
    t: (val: string) => string,
    isEditMode: boolean = false
) => {
    const validation = {
        firstName: yup.string().required(t("cmn:val.required")),
        lastName: yup.string().required(t("cmn:val.required")),
        zipCode: yup
            .string()
            .nullable()
            .test("valid-zip", t("cmn:val.zipCode"), (value) => {
                if (!value) return true;
                return /^[A-Za-z0-9\s-]{3,10}$/.test(value);
            }),
    };
    if (!isEditMode) {
        validation["email"] = yup
            .string()
            .required(t("cmn:val.required"))
            .email(t("ent.User:email.val.email"))
            .test("isExist", t("ent.User:email.val.uniq"), async (value) => {
                return (
                    (
                        await UserApi.postUserExist<
                            { isExist: boolean },
                            { email: string }
                        >({
                            email: value,
                        })
                    ).response?.isExist !== true
                );
            });
        validation["plainPassword"] = yup
            .string()
            .required(t("cmn:val.required"));
        validation["plainPasswordConfirm"] = yup
            .string()
            .required(t("cmn:val.required"))
            .oneOf(
                [yup.ref("plainPassword")],
                t("ent.User:plainPasswordConfirm.val.match")
            );
    }
    return yup.object().shape({ ...validation });
};
