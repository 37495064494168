import * as yup from "yup";

export const schema = (t: (val: string) => string) => {
    const validation = {
        name: yup.string().required(t("cmn:val.required")),
        latitude: yup
            .string()
            .nullable()
            .test("valid-latitude", t("cmn:val.latitude"), (value) => {
                if (value) {
                    const decimalRegex =
                        /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
                    if (!decimalRegex.test(value)) {
                        return false;
                    }
                    const latitude = parseFloat(value);
                    return latitude >= -90 && latitude <= 90;
                }
                return true;
            }),
        longitude: yup
            .string()
            .nullable()
            .test("valid-longitude", t("cmn:val.longitude"), (value) => {
                if (value) {
                    const decimalRegex =
                        /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
                    if (!decimalRegex.test(value)) {
                        return false;
                    }
                    const longitude = parseFloat(value);
                    return longitude >= -180 && longitude <= 180;
                }
                return true;
            }),
        zipCode: yup
            .string()
            .nullable()
            .test("valid-zip", t("cmn:val.zipCode"), (value) => {
                if (!value) return true;
                return /^[A-Za-z0-9\s-]{3,10}$/.test(value);
            }),
    };

    return yup.object().shape({ ...validation });
};
