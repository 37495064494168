import { FC } from "react";
import { useTrans } from "../../../hooks";
import { Text, Badge, HStack } from "@chakra-ui/react";
import {
    DataType,
    IKaTableProps,
    ITableInstance,
    Table as KaTable,
    SortingMode,
} from "ka-table";
import { Column, PagingOptions } from "ka-table/models";
import { ICellTextProps } from "ka-table/props";
import {
    AppOptionBoxItem,
    AppOptionsBox,
    KaPageSizeSelector,
} from "../../../components";

export const CompanyList: FC<{
    table: ITableInstance;
    paging: PagingOptions;
    list: any[];
    loadingList?: boolean;
    onActionClick?: (key: string, data?: any) => void;
}> = ({
    table,
    paging,
    list,
    loadingList = false,
    onActionClick = () => {},
}) => {
    const { t } = useTrans();
    const columns: Column[] = [
        {
            key: "name",
            title: t("ent.Company:name.label"),
            dataType: DataType.String,
        },
        {
            key: "externalCode",
            title: t("ent.Company:externalCode.label"),
            dataType: DataType.String,
            isSortable: false,
        },
        {
            key: "siret",
            title: t("ent.Company:siret.label"),
            dataType: DataType.String,
            isSortable: false,
        },
        {
            key: "phone",
            title: t("ent.Company:phone.label"),
            dataType: DataType.String,
            isSortable: false,
        },
        {
            key: "city",
            title: t("ent.Company:city.label"),
            dataType: DataType.String,
        },
        {
            key: "isActive",
            title: t("ent.Company:isActive.label"),
            dataType: DataType.Boolean,
        },
        {
            key: "action",
            title: "",
            style: { textAlign: "right" },
            dataType: DataType.Object,
            width: 50,
            isSortable: false,
        },
    ];

    const actionOptions: AppOptionBoxItem[] = [
        {
            key: "serviceGroup",
            label: t("padm.CompanyPage:text.option.serviceGroup"),
            iconName: "icl-chart-pie",
        },
        {
            key: "detail",
            label: t("cmn:option.detail"),
            iconName: "icl-eye",
        },
        {
            key: "edit",
            label: t("cmn:option.edit"),
            iconName: "icl-pencil",
        },
        {
            key: "delete",
            label: t("cmn:option.delete"),
            iconName: "icl-trash",
        },
    ];

    const kaTableProps: IKaTableProps = {
        table: table,
        columns: columns,
        data: list,
        loading: {
            enabled: loadingList,
        },
        paging: paging,
        rowKeyField: "id",
        sortingMode: SortingMode.SingleRemote,
        childComponents: {
            pagingSizes: {
                content: (props) => <KaPageSizeSelector {...props} />,
            },
            cellText: {
                content: (props: ICellTextProps) => {
                    switch (props?.column?.key) {
                        case "name":
                            return (
                                <Text textStyle={"mediumSm"} color={"black"}>
                                    {props?.rowData?.name}
                                </Text>
                            );
                        case "isActive":
                            return (
                                <Badge
                                    variant={
                                        props?.rowData?.isActive
                                            ? "green"
                                            : "red"
                                    }
                                >
                                    {t(
                                        `cmn:label.active.${props?.rowData?.isActive ? props?.rowData?.isActive : "null"}`
                                    )}
                                </Badge>
                            );
                        case "action":
                            return (
                                <HStack justify={"flex-end"} gap={3}>
                                    <AppOptionsBox
                                        options={actionOptions}
                                        data={props?.rowData}
                                        onClick={onActionClick}
                                    />
                                </HStack>
                            );
                    }
                },
            },
        },
    };

    return <KaTable {...kaTableProps} />;
};
