import { EntityApi } from "../../services/ApiPlatform";

export abstract class ServiceApi extends EntityApi {
    protected static GET_COLLECTION = "/api/services";

    protected static POST_COLLECTION = "/api/services";

    protected static GET_ITEM = "/api/services/{id}";

    protected static PUT_ITEM = "/api/services/{id}";

    protected static PATCH_ITEM = "/api/services/{id}";

    protected static DELETE_ITEM = "/api/services/{id}";
}
