import { FC, useEffect, useState } from "react";
import { FormContainerStateReturnType, useTrans } from "../../../hooks";
import {
    VStack,
    Input,
    Textarea,
    Box,
    Stack,
    useToast,
    SimpleGrid,
    HStack,
    Button,
    Divider,
    Text,
} from "@chakra-ui/react";
import {
    CompanyApi,
    ServiceGroup as Entity,
    ServiceGroupApi as EntityApi,
    Service,
} from "../../../apis";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema";
import { AppFormContainer } from "../../../containers";
import { AppFormControl, AppIcon } from "../../../components";
import { APPC } from "../../../config";

export const ServiceGroupForm: FC<{
    formContainerState: FormContainerStateReturnType;
    companyId: string;
}> = ({ formContainerState, companyId }) => {
    // hook
    const { t } = useTrans();
    const toast = useToast();

    // state & const
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<Entity>(new Entity());

    const {
        handleSubmit,
        register,
        reset,
        control,
        formState: { errors },
    } = useForm<Entity>({
        resolver: yupResolver(schema(t)),
        mode: "onSubmit",
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "services",
    });

    // handler
    const onSubmitHandler = (formData: Entity) => {
        setLoading(true);
        formData.company = CompanyApi.toResourceIRI(companyId);
        EntityApi.createOrUpdate<Entity, Entity>(
            formContainerState.entityId,
            formData
        )
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    toast({
                        title: t("cmn:message.save.success"),
                        status: "success",
                    });
                    reset();
                    formContainerState.close();
                }
            })
            .finally(() => setLoading(false));
    };

    // useEffect
    useEffect(() => {
        reset();
        setData(new Entity());
        if (!formContainerState.entityId) {
            return;
        }
        setLoading(true);
        EntityApi.getItem<Entity>(formContainerState.entityId)
            .then(({ errorMessage, isNotFound, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (isNotFound) {
                    toast({
                        title: t("cmn:message.entityNotFound"),
                        status: "error",
                    });
                } else if (response !== null) {
                    reset();
                    setData(response);
                    const services: Service[] = response?.services as Service[];
                    if (services) {
                        services.forEach((service) =>
                            append(service, { shouldFocus: false })
                        );
                    }
                }
            })
            .finally(() => setLoading(false));
    }, [formContainerState.entityId]);

    return (
        <AppFormContainer
            title={t("padm.ServiceGroupForm:text.pageTitle")}
            state={formContainerState}
            type={APPC.FORM_CONTAINER}
            size="xl"
        >
            <form
                id="app-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(onSubmitHandler)();
                }}
            >
                <VStack gap={5} alignItems={"stretch"}>
                    <AppFormControl
                        label={t("ent.ServiceGroup:name.label")}
                        isInvalid={!!errors.name}
                        message={errors.name?.message}
                        isLoading={loading}
                    >
                        <Input
                            type="text"
                            placeholder={t("ent.ServiceGroup:name.placeholder")}
                            {...register("name")}
                            defaultValue={data.name}
                        />
                    </AppFormControl>
                    <Stack
                        w={"full"}
                        gap={5}
                        direction={{ base: "column", sm: "column", md: "row" }}
                    >
                        <Box w={{ base: "full", sm: "full", md: "50%" }}>
                            <AppFormControl
                                label={t("ent.ServiceGroup:address.label")}
                                isLoading={loading}
                            >
                                <Textarea
                                    h="131px"
                                    resize={"none"}
                                    placeholder={t(
                                        "ent.ServiceGroup:address.placeholder"
                                    )}
                                    {...register("address")}
                                    defaultValue={data.address}
                                />
                            </AppFormControl>
                        </Box>
                        <Box w={{ base: "full", sm: "full", md: "50%" }}>
                            <VStack justifyContent={"space-between"} gap={5}>
                                <AppFormControl
                                    label={t("ent.ServiceGroup:zipCode.label")}
                                    isInvalid={!!errors.zipCode}
                                    message={errors.zipCode?.message}
                                    isLoading={loading}
                                >
                                    <Input
                                        type="text"
                                        placeholder={t(
                                            "ent.ServiceGroup:zipCode.placeholder"
                                        )}
                                        {...register("zipCode")}
                                        defaultValue={data.zipCode}
                                    />
                                </AppFormControl>
                                <AppFormControl
                                    label={t("ent.ServiceGroup:city.label")}
                                    isLoading={loading}
                                >
                                    <Input
                                        type="text"
                                        placeholder={t(
                                            "ent.ServiceGroup:city.placeholder"
                                        )}
                                        {...register("city")}
                                        defaultValue={data.city}
                                    />
                                </AppFormControl>
                            </VStack>
                        </Box>
                    </Stack>
                    <Stack
                        gap={5}
                        direction={{ base: "column", sm: "column", md: "row" }}
                    >
                        <AppFormControl
                            label={t("ent.ServiceGroup:latitude.label")}
                            isInvalid={!!errors.latitude}
                            message={errors.latitude?.message}
                            isLoading={loading}
                        >
                            <Input
                                type="text"
                                placeholder={t(
                                    "ent.ServiceGroup:latitude.placeholder"
                                )}
                                {...register("latitude")}
                                defaultValue={data.latitude}
                            />
                        </AppFormControl>
                        <AppFormControl
                            label={t("ent.ServiceGroup:longitude.label")}
                            isInvalid={!!errors.longitude}
                            message={errors.longitude?.message}
                            isLoading={loading}
                        >
                            <Input
                                type="text"
                                placeholder={t(
                                    "ent.ServiceGroup:longitude.placeholder"
                                )}
                                {...register("longitude")}
                                defaultValue={data.longitude}
                            />
                        </AppFormControl>
                    </Stack>
                    <Divider
                        borderColor={"var(--chakra-colors-greyT65)"}
                        mt={4}
                    />
                    <VStack gap={3} alignItems={"stretch"}>
                        <HStack justifyContent={"space-between"}>
                            <Text textStyle={"semiBoldLg"}>
                                {t("padm.ServiceGroupForm:text.services")}
                            </Text>
                            <Button
                                variant={"unstyled"}
                                onClick={() =>
                                    append({
                                        name: "",
                                    } as Service)
                                }
                            >
                                <AppIcon name="ics-plus-circle" w="1.30rem" />
                            </Button>
                        </HStack>
                    </VStack>
                    <SimpleGrid
                        columns={{ sm: 2, md: 3, lg: 3 }}
                        spacing={5}
                        p={2}
                    >
                        {fields.map((services, index) => (
                            <HStack
                                key={services.id}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                            >
                                <AppFormControl
                                    isLoading={loading}
                                    isInvalid={
                                        !!errors?.services?.[index]?.["name"]
                                    }
                                    message={
                                        errors?.services?.[index]?.["name"]
                                            ?.message
                                    }
                                >
                                    <Input
                                        type="text"
                                        placeholder={t(
                                            "ent.Service:name.placeholder"
                                        )}
                                        {...register(`services.${index}.name`)}
                                    />
                                </AppFormControl>
                                <Button
                                    variant={"unstyled"}
                                    onClick={() => remove(index)}
                                    color={"var(--chakra-colors-destructive)"}
                                >
                                    <AppIcon name="icl-trash" w="1.25rem" />
                                </Button>
                            </HStack>
                        ))}
                    </SimpleGrid>
                </VStack>
            </form>
        </AppFormContainer>
    );
};
