import * as yup from "yup";

export const schema = (t: (val: string) => string) => {
    const validation = {
        name: yup.string().required(t("cmn:val.required")),
        type: yup.string().required(t("cmn:val.required")),
    };

    return yup.object().shape({ ...validation });
};
