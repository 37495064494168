import { EntityApi } from "../../services/ApiPlatform";

export abstract class RegionApi extends EntityApi {
    protected static GET_COLLECTION = "/api/regions";

    protected static POST_COLLECTION = "/api/regions";

    protected static GET_ITEM = "/api/regions/{id}";

    protected static PUT_ITEM = "/api/regions/{id}";

    protected static PATCH_ITEM = "/api/regions/{id}";

    protected static DELETE_ITEM = "/api/regions/{id}";
}
