import { Box, Flex } from "@chakra-ui/react";

export interface AppRenderDetailItemProps {
    children?: JSX.Element;
    label?: string;
    value?: string | number;
    renderType?: "IMAGE";
    noOfLines?: number;
    imageUrl?: string;
}

export const AppRenderDetailItem = ({
    children,
    label = "",
    value = "",
    renderType,
    noOfLines = 1,
    imageUrl = "",
}: AppRenderDetailItemProps) => {
    if (renderType === "IMAGE") {
        return (
            <Box
                w={"full"}
                h={"full"}
                backgroundImage={imageUrl}
                backgroundSize={"contain"}
                backgroundRepeat={"no-repeat"}
                backgroundPosition={"center"}
            ></Box>
        );
    }
    return (
        <>
            <Flex direction={"column"} gap={1} w={"full"}>
                {label && (
                    <Box textStyle={"mediumXs"} textDecoration={"underline"}>
                        {label}
                    </Box>
                )}
                {children ? (
                    children
                ) : (
                    <Box
                        textStyle={"regularSm"}
                        noOfLines={noOfLines ? noOfLines : 1}
                    >
                        {value ? value : "-"}
                    </Box>
                )}
            </Flex>
        </>
    );
};
